import React from "react";
import DriverList from "../components/DriverManagement/DriverList";
import PageHeader from "../layout/PageHeader";
import { driverActions } from "../redux/driverSlice";
const DriverManagement = () => {
  return (
    <div className="m-10">
      <PageHeader pageTtitle="Driver Management" onChangeFunc={driverActions.setSearch}/>
      <DriverList />
    </div>
  );
};

export default DriverManagement;
