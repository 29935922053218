import React from "react";

import { Navigate, Outlet } from "react-router-dom";

const useAuth = () => {
  if (localStorage.getItem("authorization")) {
    // console.log(window.location.pathname)
    const uidb64 = window.location?.pathname?.split("/")[3];
    const ttoken = window.location?.pathname?.split("/")[4];
    if(window.location.pathname == `/admin/forgot-password/${uidb64}/${ttoken}/`){
      return false
     
    }
    return true;
  } else {
    return false;
  }
};

const PublicRoutes = (props) => {
  const auth = useAuth();

  return auth ? <Navigate to="/admin/dashboard" /> : <Outlet />;
};

export default PublicRoutes;
