import { Outlet, Navigate } from "react-router-dom";
const autoLogin = () => {
  // console.log(window.location.pathname)
  if (localStorage.getItem("authorization")) {
    if(window.location.pathname == "admin/forgot-password/:id/:token/"){
      return false
     
    }
    return true;
  } else {
    return false;
  }
};

const ProtectedRoutes = () => {
  const isAuth = autoLogin();

  return isAuth ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
