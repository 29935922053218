import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomerPageHeader from "../CustomerManagement/CustomerPageHeader";
import { useSelector, useDispatch } from "react-redux";
import { acceptTrip, contractRequestActions } from "../../redux/contractRequestsSlice";
import ModalLayout from "../../common/ModalLayout";
import { rejectTrip } from "../../redux/contractRequestsSlice";
import PageHeaderContractReqTripDetails from "./PageHeaderContractReqTripDetails";
import { useNavigate } from "react-router-dom";
import { tConvert } from "../../common/timeConverter";
import { message } from "antd";

const ContractReqTripDetails = () => {
  const contractState = useSelector((state) => state.contractrequest);
  const authState = useSelector((state)=>state.auth)
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [editAcess,setEditAcess] = useState(false)
  const [messageApi, contextHolder] = message.useMessage();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const showRejectModal = () => {
    setRejectModal(true);
  };

  const success = (content) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };

  const error = (content) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };

  const SubTitle = () => {
    return (
      <div className="flex items-center my-2">
        <Link
          to="/admin/contract-requests"
          className="leading-5 text-base font-medium text-breadcrumb hover:text-black black-text"
        >
          Contract requests
        </Link>

        <div className="inline-flex items-center justify-center w-2 h-2 mx-3 bg-breadcrumb border-breadcrumb rounded-full "></div>

        <span className="leading-5 text-base font-medium text-header-title font-lg text-lg">
          Trip details
        </span>
      </div>
    );
  };
const authEditAccessContract = []
// console.log(authState.acessMenus)
useEffect(()=>{
  // console.log(authState.acessMenus)
  for(let i =0 ; i <authState.acessMenus.length;i++){

    if(authState.acessMenus[i].menu == "contract"){
    //  console.log(authState.acessMenus[i].menu == "contract")
    //  console.log(Object.keys(authState.acessMenus[i]?.actions))
   for(let j = 0; j < Object.keys(authState.acessMenus[i]?.actions).length ; j++){
       if(authState.acessMenus[i].actions.change){
        // console.log(true)
       setEditAcess(true)
       }
   }
  
    }
  }
},[])

useEffect(()=>{
  dispatch(contractRequestActions.clearAllFilters())
},[])





const checkTripDateAlreadyStarted = (date)=>{
  const datesArray = date.split("/")
  const currentDate = new Date();
  const targetDate = new Date(datesArray[2], datesArray[1]- 1, datesArray[0]); 
  if(currentDate>targetDate){
    return true
  }else{
    return false
  }
}
  return (
    <div className="m-10">
      {contextHolder}
      <PageHeaderContractReqTripDetails pageTtitle="Trip Details" SubTitle={SubTitle} />
      <div className="flex justify-between  bg-white rounded-3xl mb-8 gap-12 p-6 ">
        <div className="flex   w-[100%]">
          <div className="flex flex-col item-center w-[100%] ">
            <div className="flex justify-between w-[100%] pb-4">
              <div>
                <p className="text-xl font-extrabold text-orange-500	">
                  {contractState.selectedContract.trip_type }
                </p>
                {contractState.selectedContract.trip_type ==
                  "Contract Trip" && (
                  <p className="text-sm ">
                    Contract name :{" "}
                    {contractState.selectedContract.trip_type !== "Day Trip" &&
                      contractState.selectedContract.contract_name}
                  </p>
                )}

                <p className="text-sm ">
                  Trip Id: {contractState.selectedContract.trip_id}
                </p>
              </div>
              <div>
                {/* <p className="px-16 py-2 text-md bg-orange-100 rounded-lg font-bold text-orange-500">
                  Paid
                </p> */}
                <button
                  type="button"
                  className=" ml-10 w-[150px] h-[30px] lg:ml-5 my-1 text-orange-500 border-solid border-2 border-slate-500 bg-white hover:bg-secondary  rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
                  onClick={() => {

                   showRejectModal();
                  
                  }}
                  disabled={!editAcess}
                >
                  Reject
                </button>
                <button
                  type="button"
                  disabled={!editAcess}
                  className=" ml-10 w-[150px] h-[30px] lg:ml-5 my-1 text-white bg-primary hover:bg-secondary border-solid border-2 border-slate-500 rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
                  onClick={() =>{

                    if( checkTripDateAlreadyStarted(contractState.selectedContract.start_date)){
                      error("Trip date has been already passed")
                    }else{
                      dispatch(contractRequestActions.setAPIloading(true))
                      dispatch(
                        acceptTrip(contractState.selectedContract.id)
                      ).then((response) => {
                        if (response.payload.status == 200) {
                          showModal();
                          setTimeout(()=>navigate("/admin/contract-requests"),2000)
                        }
                      })
                    }


                   
                 
                  }
                   
                  }
                  //then open modal and show the status
                >
                  Accept
                </button>
              </div>
            </div>
            <div className="flex justify-between w-[100%]  font-bold text-sm border-b  border-solid pb-5 border-slate-100">
              <p className="text-zinc-400	"> {contractState.selectedContract.trip_type == "Contract Trip" ? "Contract start date and time" : "Journey date and time"}</p>
              <p>
                {contractState.selectedContract.start_date} &nbsp; &nbsp;{" "}
                {tConvert(contractState.selectedContract.start_time)}
              </p>
            </div>
            {
              contractState.selectedContract.trip_type == "Contract Trip" &&
              <div className="flex justify-between w-[100%]  font-bold text-sm border-b  border-solid pb-5 border-slate-100 mt-2">
              <p className="text-zinc-400	"> Contract end date and time</p>
              <p>
                {contractState.selectedContract.end_date} &nbsp; &nbsp;{" "}
                {tConvert(contractState.selectedContract.end_time)}
              </p>
            </div>
            }
            {/* <div className="border-b  border-solid  border-slate-100"></div> */}
            {/* <hr /> */}
           {
            contractState.selectedContract.trip_type != "Contract Trip"
            &&  <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid py-3 border-slate-100">
            <p className="text-zinc-400	text-sm ">Booked for</p>
            <p className="	text-sm ">
              {contractState.selectedContract.day_trip_hour} hours
            </p>
          </div> 
           }
           
            <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid py-3 border-slate-100">
              <p className="text-zinc-400	text-sm ">Pickup location</p>
              <p>
            
                {contractState.selectedContract.pickup_point?.adress}
                
              </p>
            </div>

            {/* {
            contractState.selectedContract.adresses?.length > 0 &&  <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid py-3 border-slate-100">
            <p className="text-zinc-400	text-sm ">stops</p>
            {console.log(contractState.selectedContract.adresses)}
            <div>
              {contractState.selectedContract.adresses.map((ad=>(
                
            <p className="	text-sm ">
          <span className="font-normal"> Stop {ad.stop_number} &nbsp; : &nbsp; </span>
           {ad.adress_id.adress} 
             </p>
              )))}
            </div>
           
          </div> 
           } */}

{contractState.selectedContract.trip_type == "Contract Trip" && <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid py-3 border-slate-100">
              <p className="text-zinc-400	text-sm ">Dropoff location</p>
              {contractState.selectedContract.drop_of_point?.adress} 
             
            </div>}
            <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid py-3 border-slate-100">
              <p className="text-zinc-400	text-sm ">Trip type</p>
             {contractState.selectedContract.trip_type == "Contract Trip" &&  <p> {(contractState.selectedContract.trip_type =="Contract Trip"&& contractState.selectedContract.same_as_pickup_point)? "Two way trip" : "One way trip"}</p>}
              {contractState.selectedContract.trip_type == "Day Trip" && <p>{contractState.selectedContract.trip_type == "Day Trip" && contractState.selectedContract.trip_type }</p>}
            </div>

         {/* {contractState.selectedContract.trip_type == "Contract Trip" &&    <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid py-3 border-slate-100">
              <p className="text-zinc-400	text-sm ">Region</p>
              <p>
              {contractState.selectedContract.region?.name}
              
                
              </p>
            </div>} */}

            {contractState.selectedContract.trip_type != "Contract Trip"?<div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid py-3 border-slate-100">
              <p className="text-zinc-400	text-sm ">Vehicle type</p>
              <p className=" font-extrabold 	">
                {" "}
                {contractState.selectedContract.vehicle_type}
              </p>
            </div>: <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid py-3 border-slate-100">
              <p className="text-zinc-400	text-sm ">Driver type</p>
              <p> {contractState.selectedContract.service_type}</p>
            </div>}

          </div>
          <ModalLayout
            // title="Driver on boarding"
            width={500}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            className="flex justify-center items-center"
          >
            <div className=" h-[200px] flex  items-center text-center justify-center">
              <p className="text-xl font-extrabold text-orange-500 ">
                Ride information has been shared <br /> to the driver
              </p>
            </div>
          </ModalLayout>
          <ModalLayout
            // title="Reason for rejection"
            width={750}
            isModalOpen={rejectModal}
            setIsModalOpen={setRejectModal}
            className="flex justify-center items-center"
          >
            <div className="h-[420px] ">
              <h1 className="text-2xl font-bold py-3">Reason for rejection</h1>
              <hr />
              <div className="py-7">
                <p className="pl-6 font-bold text-lg">Reason for rejection</p>
                {/* <input
                  type="text"
                  className="w-[95%] px-5 py-20 my-2 bg-bginputfield border-none font-medium text-sm  leading-6 rounded-lg outline-none"
                  placeholder="Enter reason"
                /> */}
                <div className="flex flex-col items-center">
                  <textarea
                    onChange={(e) => setRejectReason(e.target.value)}
                    value={rejectReason}
                    rows="10"
                    cols="90"
                    placeholder="Enter reason"
                    className="mt-3 bg-bginputfield border-none font-medium text-md  leading-6 rounded-lg outline-none px-5 pt-4"
                  ></textarea>
                  <button
                    type="button"
                    onClick={() => {
                      const datas = {
                        id: contractState.selectedContract.id,
                        reason: rejectReason,
                      };

                      if(rejectReason != ""){
                        dispatch(rejectTrip(datas))
                        .then(() => {
                          setRejectModal(false);
                          setTimeout(()=>navigate("/admin/contract-requests"),1000)
                        });
                      }else{
                        error("reject reason cannot be empty")
                      }
// console.log(datas)
                 
                    }}
                    className=" ml-10 w-[250px] h-[30px] lg:ml-5 my-1 text-white bg-primary mt-5 rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </ModalLayout>
        </div>
      </div>
      <div className="flex  justify-between">
        <div className="flex justify-between  bg-white rounded-3xl mb-8 gap-12 p-6 w-[48%]">
          <div className="flex gap-8  w-[100%]">
            <div className="flex flex-col item-center w-[100%] ">
              <div className="flex justify-between w-[100%] pb-2">
                <p className="font-bold text-lg">Customer details</p>
              </div>
              <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid py-3 border-slate-100">
                <p className="text-zinc-400	">Customer name</p>
                <p>
                  {contractState.selectedContract.user_id.first_name}{" "}
                  {contractState.selectedContract.user_id.last_name}
                </p>
              </div>
              <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid py-3 border-slate-100">
                <p className="text-zinc-400	">Mobile number</p>
                <p>{contractState.selectedContract.user_id.mobile_number}</p>
              </div>
              <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid py-3 border-slate-100">
                <p className="text-zinc-400	">Whatsapp number</p>
                <p>{contractState.selectedContract.user_id.watsap_number}</p>
              </div>
              <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid py-3 border-slate-100">
                <p className="text-zinc-400	">Email ID </p>
                <p>{contractState.selectedContract.user_id.email}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between  bg-white rounded-3xl mb-8 gap-12 p-6 w-[48%]">
          <div className="flex gap-8  w-[100%]">
            <div className="flex flex-col item-center w-[100%] gap-8">
              <div className="flex justify-between w-[100%] pb-2">
                <p className="font-bold text-lg">Price details</p>
              </div>
              <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid pb-3 border-slate-100">
                <p className="text-zinc-400	">Cost</p>
                <p> {contractState.selectedContract.price} OMR</p>
              </div>
              <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid pb-3 border-slate-100">
                <p className="text-zinc-400	">Credit points added</p>
                <p>{contractState.selectedContract.credit_point} OMR</p>
              </div>

              <div className="flex justify-between w-[100%] mt-4 text-2xl font-bold text-sm">
                <p className="text-zinc-400	">Total</p>
                <p>{contractState.selectedContract.total_amount} OMR</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractReqTripDetails;
