import React, { useState, useEffect } from "react";
import logo from ".././assets/images/Otaxi_logo.png";
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from "yup";
import TextError from "../forms/TextError";
import ForgotPasswordModal from "../components/Login/ForgotPassword";
import ModalLayout from ".././common/ModalLayout";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/authSlice";
import { useNavigate } from "react-router-dom";
import { Button, message, Space } from "antd";
import eye from ".././assets/images/eye.png";

const validationSchema = Yup.object({
  username: Yup.string()
    .required("Username is required")
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
  password: Yup.string().required("Password is required"),
  // .email("Please enter a valid Password"),
});

const Login = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [forgotModal, setForgotModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const authState = useSelector((state) => state.auth);
  const navigation = useNavigate();
  const initialValues = {
    username: "",
    password: "",
  };

  const success = (content) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };

  const error = (content) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };
  const dispatch = useDispatch();
  const onSubmit = (values, submitProps) => {
    let data = {};
    data.username = values.username;
    data.password = values.password;

    dispatch(login(data))
      .then((data) => {
        if (data.payload.error) {
          error(data.payload.error);
        }
        // if (data.payload.status == 200) {
        //   success("Logged in successfully");
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (authState.loggedIn) {
      navigation("/admin/dashboard");
    } else {
      navigation("/");
    }
  }, [authState.loggedIn]);

  return (
    <div className="flex ">
      {contextHolder}
      <div className=" w-[50%] flex flex-col items-center login-container-otaxi ">
        <div className="mt-16 pt-2">
          <img src={logo} alt="" className="h-12 w-30" />
        </div>
        <div className="mt-20 pt-10 pb-20 text-center	 ">
          <p className="font-bold text-3xl">Login</p>
          <hr className="w-24 h-1 mx-auto my-4 bg-amber-500	 border-0 rounded md:my-10 dark:bg-gray-700"></hr>
        </div>
        <div className="flex">
          <Formik 
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
            validateOnChange={false}
            svalidateOnBlur={false}
          >
            {(formik) => {
              return (
                <Form autoComplete="off">
                  <hr />
                  <div className="flex flex-col">
                    <div className="w-50">
                      <Field
                        type="text"
                        name="username"
                        id="username"
                        placeholder="Username"
                        className="w-full px-5 py-3 my-2 bg-bginputfield border-none font-medium text-lg text-form-input leading-6 rounded-lg driveronboarding-input"
                      />
                      <ErrorMessage name="username" component={TextError} />
                    </div>
                    <div className="w-[350px]">
                      <div
                       className="flex w-full px-5 py-3 my-2 bg-bginputfield border-none font-medium text-lg text-form-input leading-6 rounded-lg driveronboarding-input"
                      >
                      <Field
                        type={showPassword?"text":"password"}
                        name="password"
                        id="password"
                        placeholder="Password"
                        className="w-full bg-bginputfield outline-none"
                      />
                                     <img
          src={eye}
          id="input_img"
          onClick={() => setShowPassword(!showPassword)}
          className="cursor-pointer"
        />
                      </div>
                    
                      <ErrorMessage name="password" component={TextError} />
                      <div className="flex justify-between">
                        <div>
                          <label htmlFor="" className="flex items-center ">
                            <input
                              type="checkbox"
                              className="mr-2 w-10 h-4 rounded-[3px]"
                            />
                            Remember me
                          </label>
                        </div>
                        <div>
                          <p
                            className="underline underline-offset-auto cursor-default	"
                            onClick={() => setForgotModal(true)}
                          >
                            {" "}
                            Forgot password
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center pt-10">
                      <button
                        type="submit"
                        className="w-[200px]  lg:ml-5 my-1  bg-primary text-white hover:border-primary border-2  rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <div className=" w-[50%] bg-login h-[100vh] mediaAdjust" ></div>
      <ModalLayout
        isModalOpen={forgotModal}
        setIsModalOpen={setForgotModal}
        width={500}
      >
        <ForgotPasswordModal
          setIsModalOpen={forgotModal}
          isModalOpen={setForgotModal}
        />
      </ModalLayout>
    </div>
  );
};

export default Login;
