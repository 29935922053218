import React from "react";
import searchicon from "../../assets/images/searchicon.svg";
import notification from "../../assets/images/notification.svg";
import { useState } from "react";
import { useDispatch,useSelector } from "react-redux";
// import { searchTrip,contractRequestActions } from "../../redux/contractRequestsSlice";
// import { useEffect } from "react";
// import { customerSliceActions } from "../../redux/customerSlice";
// import debounce from "debounce";
// import { useSearchDebounce } from "../../common/useSearchDebounce";

const PageHeaderContractReqTripDetails = ({ pageTtitle, SubTitle }) => {
//   const dispatch = useDispatch();
  // const contractState = useSelector(state=>state.contractrequest)

  return (
    <>
      <div className="grid grid-cols-2 md:grid-cols-1 gap-6 mt-3 mb-10 ">
        <div className="col-span-1">
          <div className="flex text-2xl font-bold leading-9 text-header-title tracking-wider">
            {pageTtitle}
          </div>
          {SubTitle && <SubTitle />}
        </div>
        {/* <div className="col-span-1">
          <div className="flex items-center justify-end md:justify-between">
            <div className="relative w-96">
              <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                <img src={searchicon} alt="" />
              </div>
              <input
                type="text"
                id="input-group-1"
                className="bg-bgcolor border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full pl-14 p-3.5 h-[39px]"
                // onChange={(e) => debounce(dispatch(contractRequestActions.setSearch(e.target.value)),2000)}
                // onChange={(e)=>setSearchVal(e.target.value)}
                onChange={(e)=> setTimeout(()=>dispatch(customerSliceActions.setSearch(e.target.value)),900) }
                placeholder="Search"
              />
            </div>
            <button
              type="button"
              className="border font-medium rounded-lg text-sm  text-center inline-flex items-center p-1 ml-6"
            >
              <img
                src={notification}
                alt="Notification Icon"
                className="h-7 w-7"
              />
            </button>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default PageHeaderContractReqTripDetails;
