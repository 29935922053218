import { ErrorMessage, Field, Formik, Form } from "formik";
import React, { useRef, useState } from "react";
import * as Yup from "yup";
import TextError from "../TextError";
import { useDispatch,useSelector } from "react-redux";
import { createUserRole,editUserRole } from "../../redux/rolesSlice";
import { message } from "antd";


const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const validationSchema = Yup.object({
  role: Yup.string()
    .required("role is required"),
  status:Yup.string()
  .required("status is required"),
  
});

const UserRoleForm = ({  isModalOpen,setIsModalOpen,editing,role,status,permissions,id}) => {
  const dispatch = useDispatch() 
  const [isEdit,setIsEdit] = useState(false)
  const roleState = useSelector(state=>state.roles)
  const [messageApi, contextHolder] = message.useMessage();
  // console.log(editing)
  // console.log(id)
 
  const success = (content) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };
  
  const error = (content) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };
  const initialValues = {
    
    role: role ? role : "",
    status: status ? status :"",
    
    
  };

 if(editing && !roleState.apiLoading){
  // console.log(permissions)
if(permissions.length > 0 || permissions  != null){
  permissions.map(per=>{
   
    initialValues[per.menu] =[per.actions.view && "view"]  
    
    initialValues[per.menu] =[...initialValues[per.menu],per.actions.change && "change"]
// console.log(per.menu)
   })
}
 }

  // console.log(initialValues)
  const onSubmit = (values, submitProps) => {



    let data = new FormData();
    data.name = values.role;
    data.status = values.status;
    // data.
   

    if(values.contract){
      data.contract = values.contract && values.contract.length>0? Object.assign(...values.contract.map(k => ({ [k]: true }))): ""
    }
    if(values.contract_management){
      data.contract_management = values.contract_management && values.contract_management.length>0? Object.assign(...values.contract_management.map(k => ({ [k]: true }))): ""

    }
    if(values.contract_modification){
      data.contract_modification = values.contract_modification && values.contract_modification.length>0? Object.assign(...values.contract_modification.map(k => ({ [k]: true }))): ""

    }
    if(values.customer){
      data.customer = values.customer && values.customer.length>0? Object.assign(...values.customer.map(k => ({ [k]: true }))): ""

    }
    if(values.driver ){
      data.driver = values.driver && values.driver.length>0? Object.assign(...values.driver.map(k => ({ [k]: true }))): ""

    }

    if(values.user){
      data.user = values.user && values.user.length>0? Object.assign(...values.user.map(k => ({ [k]: true }))): ""

    }
   if(values.report){
    data.report = values.report && values.report.length>0? Object.assign(...values.report.map(k => ({ [k]: true }))): ""

   }
    // var array = ["one", "two", "three"],
    // object = Object.assign(...array.map(k => ({ [k]: true })));
    // data.append("checkedContractReqV",values.checkedContractReqV)
    // console.log("im clicked")
      // alert(JSON.stringify(values, null, 2));

    // console.log(values)
    if(editing){
      if(data.contract?.change){
        data.contract.view = true
      }
      if(data.contract_management?.change){
        data.contract_management.view = true
      }
      if(data.contract_modification?.change){
        data.contract_modification.view = true
      }
      if(data.customer?.change){
        data.customer.view = true
      }
      if(data.driver?.change){
        data.driver.view = true
      }
      if(data.user?.change){
        data.user.view = true
      }
      if(data.report?.change){
        data.report.view = true
      }

      if(!data.contract?.change && !data.contract?.view){
        data.contract = {}
      }
      if(!data.contract_management?.change && !data.contract_management?.view){
        data.contract_management = {}
      }
      if(!data.contract_modification?.change && !data.contract_modification?.view){
        data.contract_modification = {}
      }
      if(!data.customer?.change && !data.customer?.view){
        data.customer = {}
      }
      if(!data.driver?.change && !data.driver?.view){
        data.driver = {}
      }
      if(!data.user?.change && !data.user?.view){
        data.user = {}
      }
      if(!data.report?.change && !data.report?.view){
        data.report = {}
      }
      const senddata = {
        id:id,
        edit:{...data}
      }
      dispatch(editUserRole(senddata))
      setIsModalOpen(false)
      success("Role updated successfully")
    }else{
      if(data.contract?.change){
        data.contract.view = true
      }
      if(data.contract_management?.change){
        data.contract_management.view = true
      }
      if(data.contract_modification?.change){
        data.contract_modification.view = true
      }
      if(data.customer?.change){
        data.customer.view = true
      }
      if(data.driver?.change){
        data.driver.view = true
      }
      if(data.user?.change){
        data.user.view = true
      }
      if(data.report?.change){
        data.report.view = true
      }
      dispatch(createUserRole(data)).then(()=>setIsModalOpen(false))
      submitProps.resetForm();
      success("role created successfully")
    }

    
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnChange={false}
      svalidateOnBlur={false}
    >
      {(formik) => {
        return (
          <Form autoComplete="off">
             {contextHolder}
            <hr />
            <div className="grid grid-cols-6 gap-6 py-5">
              <div className="col-span-3 sm:col-span-6">
                <label
                  htmlFor="role"
                  className="block text-xl font-medium leading-6 text-form-label"
                >
                  Role name<span className="text-red-500">*</span>
                </label>
                <Field
                  type="text"
                  name="role"
                  id="role"
                  placeholder="Enter user role name"
                  className="w-full px-5 py-3 my-2 bg-bginputfield border-none font-medium text-xl text-form-input leading-6 rounded-lg driveronboarding-input"
                />
                <ErrorMessage name="role" component={TextError} />
              </div>

              <div className="col-span-3 sm:col-span-6 ">
                <label
                  htmlFor="status"
                  className="block text-xl font-medium leading-6 text-form-label"
                >
                  Status
                </label>
                <Field
                  as="select"
                  name="status"
                  id="status"
                  className={`${
                    formik.values.status !== ""
                      ? "driveronboarding-input"
                      : "font-medium"
                  } w-full bg-bginputfield px-5 py-2.5 my-2  border-none font-medium text-xl text-form-input leading-6 rounded-lg `}
                >
                  <option value="" disabled>
                    Select status
                  </option>
                  <option className="driveronboarding-input" value="true">Active</option>
                  <option className="driveronboarding-input" value="false">Inactive</option>
                </Field>
              </div>
            </div>
            <h2 className="text-xl">Permissions</h2>
            <div>
              <div className="flex flex-row justify-between text-base py-3">
                <p className="font-bold	">Contract Requests</p>
                <div className=" flex items-center">
             <span className="flex items-center mr-3 justify-center">
             <label className="mr-3">
                View
            </label>
            <Field type="checkbox" name="contract" value="view"  />
             </span>
             <span className="flex items-center mr-3 ">
            <label className="mr-3">
            Edit
            </label>
            <Field type="checkbox" name="contract" value="change" />
            </span>
                </div>
              </div>
              <div className="flex flex-row justify-between text-base py-3">
                <p className="font-bold	">Contract Management</p>
                <div className=" flex items-center">
             <span className="flex items-center mr-3 ">
             <label className="mr-3">
                View
            </label>
            <Field type="checkbox" name="contract_management" value="view" />
             </span>
             <span className="flex items-center mr-3 ">
            <label className="mr-3">
            Edit
            </label>
            <Field type="checkbox" name="contract_management" value="change" />
            </span>
                </div>
              </div>
              <div className="flex flex-row justify-between text-base py-3">
                <p className="font-bold	">Contract Modification Requests</p>
                <div className=" flex items-center">
             <span className="flex items-center mr-3 ">
             <label className="mr-3">
                View
            </label>
            <Field type="checkbox" name="contract_modification" value="view" />
             </span>
             <span className="flex items-center mr-3 ">
            <label className="mr-3">
            Edit
            </label>
            <Field type="checkbox" name="contract_modification" value="change" />
            </span>
                </div>
              </div>
              <div className="flex flex-row justify-between text-base py-3">
                <p className="font-bold	">Customers Management</p>
                <div className=" flex items-center">
             <span className="flex items-center mr-3 ">
             <label className="mr-3">
                View
            </label>
            <Field type="checkbox" name="customer" value="view" />
             </span>
             <span className="flex items-center mr-3 ">
            <label className="mr-3">
            Edit
            </label>
            <Field type="checkbox" name="customer" value="change" />
            </span>
                </div>
              </div>
              <div className="flex flex-row justify-between text-base py-3">
                <p className="font-bold	">Driver Management</p>
                 <div className=" flex items-center">
             <span className="flex items-center mr-3 ">
             <label className="mr-3">
                View
            </label>
            <Field type="checkbox" name="driver" value="view" />
             </span>
             <span className="flex items-center mr-3 ">
            <label className="mr-3">
            Edit
            </label>
            <Field type="checkbox" name="driver" value="change" />
            </span>
                </div>
              </div>
              <div className="flex flex-row justify-between text-base py-3">
                <p className="font-bold	">User Mangement</p>
                <div className=" flex items-center">
             <span className="flex items-center mr-3 ">
             <label className="mr-3">
                View
            </label>
            <Field type="checkbox" name="user" value="view" />
             </span>
             <span className="flex items-center mr-3 ">
            <label className="mr-3">
            Edit
            </label>
            <Field type="checkbox" name="user" value="change" />
            </span>
                </div>
              </div>
              <div className="flex flex-row justify-between text-base py-3">
                <p className="font-bold	">Report</p>
                <div className=" flex items-center">
             <span className="flex items-center mr-3 ">
             <label className="mr-3">
                View
            </label>
            <Field type="checkbox" name="report" value="view" />
             </span>
             <span className="flex items-center mr-3 ">
            <label className="mr-3">
            Edit
            </label>
            <Field type="checkbox" name="report" value="change" />
            </span>
                </div>
              </div>
            </div>
            <div className="col-span-6 sm:col-span-6 pt-6 mt-3">
              <div className="flex gap-3 items-center justify-center flex-wrap">
                <button
                 
                  type="button"
                  className="w-[200px]  lg:ml-5 my-1 text-primary hover:bg-primary hover:text-white hover:border-primary border-2 border-black rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
                  // onClick={() => setIsModalOpen(!isModalOpen)}
                >
                  Reject
                </button>
                <button
                  //  type="submit"
                  type="submit"
                  className="w-[200px]  lg:ml-5 my-1 text-primary hover:bg-primary hover:text-white hover:border-primary border-2 border-black rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
                >
                  Accept
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserRoleForm;
