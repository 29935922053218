import React, { useEffect, useLayoutEffect, useState } from "react";
import logo from ".././assets/images/Otaxi_logo.png";
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from "yup";
import TextError from "../forms/TextError";
import ForgotPasswordModal from "../components/Login/ForgotPassword";
import ModalLayout from ".././common/ModalLayout";
import { useDispatch } from "react-redux";
import { authActions, updatePassword } from "../redux/authSlice";
import { Radio, message } from "antd";
import { useNavigate } from "react-router-dom";
import eye from ".././assets/images/eye.png";

const validationSchema = Yup.object({
  password: Yup.string()
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol"),
  confirm_password: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords should match"
  ),
});

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [forgotModal, setForgotModal] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  // const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword,setShowConfirmPassword] = useState(false)
  const initialValues = {
    password: "",
    confirm_password: "",
  };

// useEffect(()=>{
 
// },[])

useLayoutEffect(()=>{
  dispatch(authActions.logout())
},[])

  const success = (content) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };

  const error = (content) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };

  const onSubmit = (values, submitProps) => {
    let data = {};
    data.password = values.password;
    data.uidb64 = window.location.pathname.split("/")[3];
    data.token = window.location.pathname.split("/")[4];

    dispatch(updatePassword(data)).then((res) => {
      // console.log(res)
      // console.log(res.status)
      // if(res.status == 401){
      //   console.log(res.data?.detail)
      //   error(res.data?.detail);
      // }
      if (res.payload?.detail ) {
        error(res.payload?.detail);
        // error(res.data?.detail);
      }
      if (res.payload?.success) {
        success("Password updated succesfully");
        setTimeout(() => navigate("/"), 1500);
      }
  
     
    });
  };

  // const hello =  ()=>{return <div className="flex w-full px-5 py-3 my-2 bg-bginputfield border-none font-medium text-md text-form-input leading-6 rounded-lg driveronboarding-input" id="input_container"><input placeholder="New password" className="bg-bginputfield outline-none  w-full" type={`${showPassword?"text":"password"}`} id="input" value={password} onChange={(e)=>setPassword(e.target.value)}/><img src={eye} id="input_img" onClick={()=>setShowPassword(!showPassword)} className="cursor-pointer"/></div>}

  return (
    <div className="flex">
      {contextHolder}
      <div className=" w-[50%] flex flex-col items-center">
        <div className="mt-20 pt-10">
          <img src={logo} alt="" className="h-12 w-30" />
        </div>
        <div className="mt-20 pt-10 pb-20 text-center	 ">
          <p className="font-bold text-3xl">Reset Password</p>
          <hr className="w-24 h-1 mx-auto my-4 bg-amber-500	 border-0 rounded md:my-10 dark:bg-gray-700"></hr>
        </div>
        <div className="flex">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
            validateOnChange={false}
            svalidateOnBlur={false}
          >
            {(formik) => {
              return (
                <Form autoComplete="off">
                  <hr />
                  <div className="flex flex-col">
                    <div className="w-50">
                      <div 
                        className="flex w-full px-5 py-3 my-2 bg-bginputfield border-none font-medium text-md text-form-input leading-6 rounded-lg driveronboarding-input"
                      
                      >
                      <Field
                        type={showPassword?"text":"password"}
                        name="password"
                        id="password"
                        placeholder="New password"
                        className="w-full bg-bginputfield outline-none"
                      /> 
                      <img
          src={eye}
          id="input_img"
          onClick={() => setShowPassword(!showPassword)}
          className="cursor-pointer"
        />
                      </div>
                      
                      <ErrorMessage name="password" component={TextError} />
                    </div>
                    <div className="w-[350px]">
                      <div className="flex w-full px-5 py-3 my-2 bg-bginputfield border-none font-medium text-md text-form-input leading-6 rounded-lg driveronboarding-input">
                      <Field
                       type={showConfirmPassword?"text":"password"}
                        name="confirm_password"
                        id="confirm_password"
                        placeholder="confirm password"
                        className="w-full bg-bginputfield outline-none"
                        // className="w-full px-5 py-3 my-2 bg-bginputfield border-none font-medium text-md text-form-input leading-6 rounded-lg driveronboarding-input"
                        // component={<div>hi</div>}
                      />
                         <img
          src={eye}
          id="input_img"
          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          className="cursor-pointer"
        />
                      </div>
                    

                      <ErrorMessage
                        name="confirm_password"
                        component={TextError}
                      />
                      <div className="flex justify-between">
                        <div>
                          <label
                            htmlFor=""
                            className="flex items-center justify-start"
                          >
                            <input
                              type="checkbox"
                              className="mr-2 w-10 h-4 rounded-[3px]"
                            />
                            Remember me
                          </label>
                        </div>
                      </div>
                      <div className="flex justify-center pt-10">
                        <button
                          type="submit"
                          className="w-[400px]  lg:ml-5 my-1  bg-primary text-white hover:border-primary border-2  rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
                        >
                          Change password
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <div className=" w-[50%] bg-login h-[100vh]"></div>
    </div>
  );
};

export default ResetPassword;
