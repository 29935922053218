import React, { useEffect,useState } from "react";
import PageHeader from "../layout/PageHeader";
import wallet from ".././assets/images/empty-wallet-time.svg";
import send from ".././assets/images/send-2-bw.svg";
import user from ".././assets/images/user-tick-bw.svg";
import people from ".././assets/images/people-bw.svg";
import { Select, Table,DatePicker } from "antd";
import CardLayout from ".././common/CardLayout";
import { useDispatch,useSelector } from "react-redux";
import { DownloadReports, getAllReports, getFilteredReports, reportSliceActions } from "../redux/reportSlice";
import ReportsHeader from "../components/Reports/ReportsHeader";
import download from 'js-file-download';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { getNotificationDashboard } from "../redux/dashboardSlice";
import { clearAllContractManagement, clearAllContractMod, clearAllContractRequest, clearAllCustomer, clearAllDriver, clearAllRoles, clearAllUsers } from "../common/clearfunction";
const Reports = () => {
  const dispatch = useDispatch()
  const reportState = useSelector(state=>state.report)
  const [totalCount,setTotalCount] = useState(10)
  const [pageno,setPageNo] = useState(1)
  const [loader,setLoader] = useState(true)
  const { RangePicker } = DatePicker;
  const contractManagementState = useSelector(
    (state) => state.contractmanagement
  );


useEffect(()=>{
  dispatch(reportSliceActions.setAPILoading(true))
  clearAllContractRequest()
  clearAllContractManagement()
  clearAllContractMod()
  clearAllCustomer()
  clearAllDriver()
  clearAllUsers()
  clearAllRoles()
  dispatch(getNotificationDashboard())
  if(reportState.searchText == "" && reportState.start_date =="" && reportState.end_date =="" && reportState.status == ""){
    dispatch(getAllReports(pageno)).then(res=>setTotalCount(res.payload.count))
    // setTimeout(()=>setLoader(false),800)
  }
 
},[pageno,reportState.status,reportState.start_date])
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

useEffect(()=>{
  // if(reportState.searchText != "" || reportState.start_date !="" || reportState.end_date !="" || reportState.status != ""){
    dispatch(reportSliceActions.setAPILoading(true))
    const data= {
      searchText:reportState.searchText,
      start_date:reportState.start_date,
      end_date:reportState.end_date,
      status:reportState.status,
      page:pageno
    }
    dispatch(getFilteredReports(data)).then(res=>{
      // console.log(res.payload.data)
      setTotalCount(res.payload.data.count)
      setTimeout(()=>setLoader(false),800)
    })
  
},[reportState.searchText,reportState.start_date,reportState.end_date,reportState.status,pageno])


const mapData = reportState.searchText != "" || reportState.start_date !="" || reportState.end_date !="" || reportState.status != ""? reportState.filteredReports : reportState.allReports
// console.log(reportState)
  const columns = [
    {
      title: "Contract ID/\nTrip ID",
      dataIndex: "trip_id",
      key: "trip_id",
      render: (trip_id, record) => (
        <p style={{ fontSize: "12px" }}>{trip_id}</p>
      ),
    },
    
      {
        title: "Trip\nType",
        dataIndex: "trip_type",
        key: "trip_id",
        render: (trip_type, record) => (
          <p style={{ fontSize: "12px" }}>{trip_type}</p>
        ),
      },
    
    {
      title: "Bundle",
      dataIndex: "bundle",
      key: "trip_id",
      render: (bundle, record) => <p style={{ fontSize: "12px" }}>{bundle?bundle:"Web"}</p>,
    },
    {
      title: "Passenger's \nname",
      dataIndex: "user_id",
      key: "trip_id",
      render: (user_id, record) => (
        <p style={{ fontSize: "12px" }}>{user_id.first_name}</p>
      ),
    },
    {
      title: "Passenger's \nnumber",
      key: "trip_id",
      dataIndex: "user_id",
      render: (user_id, record) => (
        <p style={{ fontSize: "12px" }}>{user_id.mobile_number}</p>
      ),
    },
    {
      title: "Passenger's \nemail",
      dataIndex: "user_id",
      key: "trip_id",
      render: (user_id, record) => (
        <p style={{ fontSize: "12px" }}>{user_id.email}</p>
      ),
    },
    {
      title: "Driver's \nname",
      dataIndex: "trip_driver",
      key: "trip_id",
      render: (trip_driver, record) => (
        <p style={{ fontSize: "12px" }}>{trip_driver == null ? "not assigned " : trip_driver.drivername}</p>
      ),
    },
    {
      title: "Driver \nnumber",
      dataIndex: "trip_driver",
      key: "trip_id",
      render: (trip_driver, record) => (
        <p style={{ fontSize: "12px" }}>{trip_driver == null ? "not assigned " : trip_driver.mobile_number}</p>
      ),
    },
    {
      title: "Booked for",
      dataIndex: "day_trip_hour",
      key: "trip_id",
      render: (day_trip_hour, record) => (
        <p style={{ fontSize: "12px" }}>{day_trip_hour} {day_trip_hour != null?" hour":"- - - -"}</p>
      ),
    },
    {
      title: "No.of.ways",
      dataIndex: "same_as_pickup_point",
      key: "trip_id",
      render: (same_as_pickup_point, record) => (
        <p style={{ fontSize: "12px" }}>{same_as_pickup_point && record.trip_type == "Contract Trip" ? "Two way trip" : !same_as_pickup_point && record.trip_type == "Contract Trip" ? "One way trip" : "- - - - - - - -"}</p>
      ),
    },
    {
      title: "Driver's \nVehicle",
      dataIndex: "trip_driver",
      key: "trip_id",
      render: (trip_driver, record) => (
        <p style={{ fontSize: "12px" }}>{trip_driver?.vehicle_type == null ? "not assigned " : trip_driver.vehicle_type}</p>
      ),
    },
    {
      title: "Vehicle\nplate number",
      dataIndex: "trip_driver",
      key: "trip_id",
      render: (trip_driver, record) => (
        <p style={{ fontSize: "12px" }}>{trip_driver == null ? "not assigned " : trip_driver.registraion_number}</p>
      ),
    },
    {
      title: "Trip\ndistance",
      dataIndex: "total_distance",
      key: "trip_id",
      render: (total_distance, record) => (
        <p style={{ fontSize: "12px" }}>{total_distance}</p>
      ),
    },
    {
      title: "Trip cost\nOMR",
      dataIndex: "price",
      key: "trip_id",
      render: (price, record) => (
        <p style={{ fontSize: "12px" }}>{price}</p>
      ),
    },
    {
      title: "Credit point\nused",
      dataIndex: "credit_point",
      key: "trip_id",
      render: (credit_point, record) => (
        <p style={{ fontSize: "12px" }}>{credit_point}</p>
      ),
    },
    {
      title: "Tax\nOMR",
      dataIndex: "",
      key: "trip_id",
      render: (total_distance, record) => (
        <p style={{ fontSize: "12px" }}>nil</p>
      ),
    },
    {
      title: "Cost of Otaxi",
      dataIndex: "price",
      key: "trip_id",
      render: (price, record) => (
        <p style={{ fontSize: "12px" }}>{(price*0.10).toFixed(2) }</p>
      ),
    },
    {
      title: "Cost of driver",
      dataIndex: "price",
      key: "trip_id",
      render: (price, record) => (
        <p style={{ fontSize: "12px" }}>{(price*0.90).toFixed(2)}</p>
      ),
    },
    {
      title: "Trip status",
      dataIndex: "status",
      key: "trip_id",
      render: (status, record) => (
        <p style={{ fontSize: "12px" }}>{status}</p>
      ),
    },
    {
      title: "Payment \n Method",
      dataIndex: "total_distance",
      key: "trip_id",
      render: (total_distance, record) => (
        <p style={{ fontSize: "12px" }}>{record?.trip_payment?.payment_mode}</p>
      ),
    },

    {
      title: "Start date",
      dataIndex: "start_date",
      key: "trip_id",
      render: (start_date, record) => (
        <p style={{ fontSize: "12px" }}>{start_date}</p>
      ),
    },
    {
      title: "Started Location",
      dataIndex: "pickup_point",
      key: "trip_id",
      render: (pickup_point, record) => (
        <p style={{ fontSize: "12px" }}>{record?.pickup_point?.adress}</p>
      ),
    },
    {
      title: "End date",
      dataIndex: "end_date",
      key: "trip_id",
      render: (end_date, record) => (
        <p style={{ fontSize: "12px" }}>{end_date != null ? end_date : "- - - - - - -"}</p>
      ),
    },

    {
      title: "Finished Location",
      dataIndex: "drop_of_point",
      key: "trip_id",
      render: (drop_of_point, record) => (
        <p style={{ fontSize: "12px" }}>{record?.drop_of_point?.adress}</p>
      ),
    },

  ];
  const data = [
    {
      key: "1",
      contract_id: "12345",
      bundle: "web",
      passengername: "walter white",
      passengersnumber: "(303) 555-503",
      passengersemail: "name@gmail.com",
      drivername: "John Brown",
      driverVehicle: "-----",
      vechicleplatenumber: "98782489",
      estimationtime: "10:00",
      tripdistance: "45 km",
    },
    {
      key: "1",
      contract_id: "12345",
      bundle: "web",
      passengername: "walter white",
      passengersnumber: "(303) 555-503",
      passengersemail: "name@gmail.com",
      drivername: "John Brown",
      driverVehicle: "-----",
      vechicleplatenumber: "98782489",
      estimationtime: "10:00",
      tripdistance: "45 km",
    },
    {
      key: "1",
      contract_id: "12345",
      bundle: "web",
      passengername: "walter white",
      passengersnumber: "(303) 555-503",
      passengersemail: "name@gmail.com",
      drivername: "John Brown",
      driverVehicle: "-----",
      vechicleplatenumber: "98782489",
      estimationtime: "10:00",
      tripdistance: "45 km",
    },
  ];


  useEffect(()=>{
    setPageNo(1)
  },[contractManagementState.setpagenumberFlag])
//   var curr = new Date; // get current date
// var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
// var last = first + 6; // last day is the first day + 6

// var firstday = new Date(curr.setDate(first)).toISOString().split('T')[0]
// console.log(firstday)
// var lastday = new Date(curr.setDate(last)).toUTCString();
  return (
    <div className="m-10">
      <PageHeader pageTtitle="Revenue Report" onChangeFunc={reportSliceActions.setSearch}/>
      <div className="flex justify-end mr-14 my-10 items-center">
      <Select
              // allowClear
              className="w-[150px]  my-1 font-bold border-none bg-[#f265220d] rounded-lg mr-2"
              defaultValue="All"
              onChange={(val)=>{
                setPageNo(1)
                dispatch(reportSliceActions.setAPILoading(true))
                dispatch(reportSliceActions.setEndDate(""))
                dispatch(reportSliceActions.setStartDate(val))
              }}
              bordered={false}
              options={[
                {
                  value: "",
                  label: "All",
                  className:
                    "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
                },
                {
                  value: "today",
                  label: "Today",
                  className:
                    "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
                },
                {
                  value: "week",
                  label: "This Week",
                  className: "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
                },
                {
                  value: "month",
                  label: "This Month",
                  className: "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
                },
                {
                  value: "year",
                  label: "This Year",
                  className: "w-full py-[.5rem] text-xs  font-bold",
                },
              ]}
            />
        <RangePicker  suffixIcon format="DD/MM/YYYY" onChange={(str,val)=>{
          setPageNo(1)
            let newdatestart = val[0].split("/").reverse().join("-");
            let newdatesend = val[1].split("/").reverse().join("-");
          dispatch(reportSliceActions.setStartDate(newdatestart))
          dispatch(reportSliceActions.setEndDate(newdatesend))
        }} style={{
          height:"32px",
          width:"220px",
          border:"none",
          backgroundColor:"rgba(41, 39, 58, 0.05)"
          
          
        }}/>
      </div>
      <div className="flex justify-around mb-10 gap-3 ">
        <div
          className="w-72 drop-shadow-md rounded-lg  flex flex-col"
          style={{ height: "150px", background: "white" }}
        >
          <div className="flex flex-col justify-center ml-5 mt-5 ">
            <div className="mb-3 h-7 w-7">
              <img src={wallet} />
            </div>
            <p className="text-4xl font-bold ">{reportState.total_revenue} OMR</p>
            <p className="font-800 text-sm">Total Revenue</p>
          </div>
        </div>
        <div
          className="w-56 drop-shadow-md rounded-lg  flex flex-col border border-solid border-black"
          style={{ height: "150px" }}
        >
          <div className="flex flex-col justify-center ml-5 mt-5 ">
            <div className="mb-3 h-7 w-7">
              <img src={user} />
            </div>
            <p className="text-4xl font-bold ">{reportState.total_customers} </p>
            <p className="font-800 text-sm">Total Customers</p>
          </div>
        </div>
        <div
          className="w-56 drop-shadow-md rounded-lg  flex flex-col border border-solid border-black"
          style={{ height: "150px" }}
        >
          <div className="flex flex-col justify-center ml-5 mt-5 ">
            <div className="mb-3 h-7 w-7">
              <img src={send} />
            </div>
            <p className="text-4xl font-bold ">{reportState.total_trips}</p>
            <p className="font-800 text-sm">Total Trips</p>
          </div>
        </div>
        <div
          className="w-56 drop-shadow-md rounded-lg  flex flex-col border border-solid border-black"
          style={{ height: "150px" }}
        >
          <div className="flex flex-col justify-center ml-5 mt-5 ">
            <div className="mb-3 h-7 w-7">
              <img src={people} />
            </div>
            <p className="text-4xl font-bold ">{reportState.total_drivers}</p>
            <p className="font-800 text-sm">Total Drivers</p>
          </div>
        </div>
      </div>

      <CardLayout>
        <div className="grid grid-cols-2 md:grid-cols-1 gap-6 my-5">
          <div className="col-span-1">
            <p className="font-bold text-xl leading-6 text-header-title my-2">
              Revenue report
            </p>
          </div>
          <div className="col-span-1 flex items-center md:justify-between justify-end flex-wrap gap-3">
            {/* <div className="my-2 flex items-center justify-between md:w-full w-1/2"> */}
            <Select
              // allowClear
              className="w-[150px]  my-1 font-bold border-none bg-[#f265220d] rounded-lg"
              defaultValue="All"
              onChange={(val)=>{
                setPageNo(1)
                dispatch(reportSliceActions.setAPILoading(true))
                dispatch(reportSliceActions.setStatus(val))}}
              bordered={false}
              options={[
                {
                  value: "",
                  label: "All",
                  className:
                    "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
                },
                {
                  value: "Contract%20Trip",
                  label: "Contract Trip",
                  className:
                    "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
                },
                {
                  value: "Day%20Trip",
                  label: "Day Trip",
                  className: "w-full py-[.5rem] text-xs  font-bold",
                },
              ]}
            />
            <button
              type="button"
              className="w-[150px]  lg:ml-5 my-1 text-white bg-primary hover:bg-secondary  rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
              // onClick={showModal}
              onClick={()=>{
                const data= {
                  searchText:reportState.searchText,
                  start_date:reportState.start_date,
                  end_date:reportState.end_date,
                  status:reportState.status
                }
                dispatch(DownloadReports(data)).then((res) => {
                  const link = document.createElement('a');
                  const url = URL.createObjectURL(res.payload.data);
                  // console.log(url);
                  link.href = url;
                  link.download = 'myfile.xlsx';
                  link.click();
                });
              }}
            >
              Download
            </button>
            {/* </div> */}
          </div>
          <div className="flex items-center justify-between flex-wrap"></div>
        </div>
        {reportState.apiLoading? <div style={{display:"flex",justifyContent:"center"}}>
          <Spin indicator={antIcon} />
        </div>:   <Table
        rowKey={record => record.id} 
          columns={columns}
          // dataSource={allDrivers}
          dataSource={mapData}
          scroll={{ x: 900 }}
          style={{ whiteSpace: "pre" }}
          pagination={{  pageSize:10 ,total:totalCount ,onChange:(page,size)=>{setPageNo(page)},showSizeChanger:false ,current:pageno}}
        />}
     
      </CardLayout>
    </div>
  );
};

export default Reports;
