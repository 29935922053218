import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Select,DatePicker } from "antd";
import PageHeaderContractReqTripDetails from "../ContractRequests/PageHeaderContractReqTripDetails";
import driver_img from "../../assets/images/driver_img.png";
import { useDispatch, useSelector } from "react-redux";
import { getAllDriverContracts, getSingleDriver } from "../../redux/driverSlice";
import fileDownload from "js-file-download";
import axios from "axios";
import DriverContracts from '../DriverManagement/DriverContracts'

// import "../../drivermanagement.scss";
import "../DriverManagement/drivermanagement.scss"
const ContractDriverPage = ({ driverId,driver_ID }) => {
  const dispatch = useDispatch();

  const contractManagementState = useSelector((state) => state.contractmanagement);

console.log(contractManagementState.currentContractModal.trip_driver)

  const SubTitle = () => {
  
// console.log(driverState.allDriverContracts)
    
    return (
      <div className="flex items-center my-2">
        <Link
          to="/admin/contract-management"
          className="leading-5 text-base font-medium text-breadcrumb hover:text-black"
        >
          Contract management
        </Link>
        
        <div className="inline-flex items-center justify-center w-2 h-2 mx-3 bg-breadcrumb border-breadcrumb rounded-full "></div>
        <span className="leading-5 text-base font-medium text-header-title">
          Driver details
        </span>
      </div>
    );
  };

  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };
  return (
    <div className="m-10">
      <PageHeaderContractReqTripDetails pageTtitle="Driver Management" SubTitle={SubTitle} />
      <div>
        <div className="flex justify-between  bg-white rounded-3xl mb-8 gap-12 p-6 ">
          <div className="flex gap-8  w-[70%]">
            <div
              style={{
                height: "180px",
                width: "180px",
                backgroundColor: "black",
                borderRadius: "20px",
              }}
            >
              <img
                style={{
                  height: "180px",
                  width: "180px",
                  objectFit: "contain",
                }}
                className="min-w-[7rem] max-w-[20rem] "
                src={
                  contractManagementState.currentContractModal.trip_driver.driver_photo
                    ? contractManagementState.currentContractModal.trip_driver.driver_photo
                    : driver_img
                }
                alt="driver"
              />
            </div>
            <div className=" flex flex-col justify-between text-secondary w-[100%] lg:w-[70%] ">
              <h3 className="text-lg font-bold mb-4">
                {contractManagementState.currentContractModal.trip_driver.driver_id}
              </h3>
              <h2 className="text-2xl font-bold -mt-5 mb-5">
                {contractManagementState.currentContractModal.trip_driver.drivername}{" "}
              </h2>
              <div className="flex justify-between w-full gap-5 ">
                <div>
                  <p className="mb-1 text-xs ">Contact number </p>
                  <h3 className="text-md font-bold">
                    {contractManagementState.currentContractModal.trip_driver.mobile_number}{" "}
                  </h3>
                </div>
                <div>
                  <p className="mb-1 text-xs ">Whatsapp number </p>
                  <h3 className="text-md font-bold">
                    {contractManagementState.currentContractModal.trip_driver.whatsapp_number}{" "}
                  </h3>
                </div>
                <div>
                  <p className="mb-1 text-xs ">Email ID </p>
                  <h3 className="text-md font-bold">
                    {contractManagementState.currentContractModal.trip_driver.email_address?contractManagementState.currentContractModal.trip_driver.email_address:"N/A"}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p
              className={`px-10 py-1 text-sm ${
                contractManagementState.currentContractModal.trip_driver.status == "Active"
                  ? "bg-active"
                  : "bg-orange-300"
              } rounded-lg`}
            >
              {contractManagementState.currentContractModal.trip_driver.status}
            </p>
          </div>
        </div>

        <div className="p-6 bg-white rounded-3xl mb-8">
          <h2 className="text-lg font-bold text-secondary mb-4">
          More details
          </h2>
          <div className="flex justify-between items-center py-5 border-b-[1px] border-textGrey text-md">
            <p className="text-base font-semibold text-greyText">
              Vehicle type
            </p>
            <p className="text-secondary text-md font-bold">
              {contractManagementState.currentContractModal.trip_driver.vehicle_type?contractManagementState.currentContractModal.trip_driver.vehicle_type:"N/A"}
            </p>
          </div>
          {/* <div className="flex justify-between items-center py-5 border-b-[1px] border-textGrey text-md">
            <p className="text-base font-semibold text-greyText">
              Vehicle registration number
            </p>
            <p className="text-secondary text-lg font-bold">
              {contractManagementState.currentContractModal.trip_driver.registraion_number}
            </p>
          </div> */}
          <div className="flex justify-between items-center py-5 border-b-[1px] border-textGrey text-md">
            <p className="text-base font-semibold text-greyText">
              Driver’s license
            </p>
     
     
              {/* <Link to={contractManagementState.currentContractModal.trip_driver.driver_license} target="_blank" download>Download</Link> */}
           {contractManagementState.currentContractModal.trip_driver.driver_license?   <a
              download
              className="text-primary text-lg font-bold underline-offset-4 decoration-1 underline text-md"
              href={contractManagementState.currentContractModal.trip_driver.driver_license}
              >
                {" "}
                Driving License.
                {contractManagementState.currentContractModal.trip_driver.driver_license
                  ? contractManagementState.currentContractModal.trip_driver.driver_license.split(".").pop()
                  : ""}
              </a>:<p className="text-secondary text-md font-bold">N/A</p>}
   
   
          </div>
          <div className="flex justify-between items-center py-5 border-b-[1px] border-textGrey">
            <p className="text-base font-semibold text-greyText">
              Motor vehicle license
            </p>
      
          {contractManagementState.currentContractModal.trip_driver.vehicle_license?    <a
              download
              className="text-primary text-lg font-bold underline-offset-4 decoration-1 underline text-md"
              href={contractManagementState.currentContractModal.trip_driver.vehicle_license}
              >
                {" "}
                {contractManagementState.currentContractModal.trip_driver.vehicle_license
                  ? `Vehicle License.${contractManagementState.currentContractModal.trip_driver.vehicle_license
                      .split(".")
                      .pop()}`
                  : ""}
              </a>:<p className="text-secondary text-md font-bold">N/A</p>}
          
          </div>
          <div className="flex justify-between items-center py-5 border-b-[1px] border-textGrey text-md">
            <p className="text-base font-semibold text-greyText">
              Card holder name
            </p>
            <p className="text-secondary text-lg font-bold">
              {contractManagementState.currentContractModal.trip_driver?.card_holder_name}
            </p>
          </div>
          <div className="flex justify-between items-center py-5 border-b-[1px] border-textGrey text-md">
            <p className="text-base font-semibold text-greyText">
              Bank account number
            </p>
            <p className="text-secondary text-lg font-bold">
              {contractManagementState.currentContractModal.trip_driver?.bank_account_number}
            </p>
          </div>
          {/* <div className="flex justify-between items-center py-5 border-b-[1px] border-textGrey text-md">
            <p className="text-base font-semibold text-greyText">
              Region
            </p>
            <p className="text-secondary text-lg font-bold">
              {contractManagementState.currentContractModal.trip_driver?.region?.name}
            </p>
          </div> */}
        </div>

       
      </div>
      
    </div>
  );
};

export default ContractDriverPage;
