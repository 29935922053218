import ContractManagement from "../pages/ContractManagement";
import ContractModificationRequests from "../pages/ContractModificationRequests";
import ContractRequests from "../pages/ContractRequests";
import CustomerManagement from "../pages/CustomerManagement";
import Dashboard from "../pages/Dashboard";
import DriverManagement from "../pages/DriverManagement";
import Reports from "../pages/Reports";
import UserManagement from "../pages/UserManagement";
import dashboardicon from "../assets/images/dashboard.svg";
import activedashboardicon from "../assets/images/active-dashboard.svg";
import contractrequesticon from "../assets/images/contract-request.svg";
import activecontractrequesticon from "../assets/images/active-contract-request.svg";
import contractmanagementicon from "../assets/images/contract-management.svg";
import activecontractmanagementicon from "../assets/images/active-contract-management.svg";
import contractmodificationrequesticon from "../assets/images/contract-modification.svg";
import activecontractmodificationrequesticon from "../assets/images/active-contract-modification.svg";
import customermanagementicon from "../assets/images/customer-management.svg";
import activecustomermanagementicon from "../assets/images/active-customer-management.svg";
import drivermanagementicon from "../assets/images/driver-management.svg";
import activedrivermanagementicon from "../assets/images/active-driver-management.svg";
import usermanagementicon from "../assets/images/user-management.svg";
import activeusermanagementicon from "../assets/images/active-user-management.svg";
import reportsicon from "../assets/images/reports.svg";
import activereportsicon from "../assets/images/active-reports.svg";
import Login from "../pages/Login";

const routs = [
  {
    path: "dashboard",
    name: "Dashboard",
    acessName: "Dashboard",
    menuicon: dashboardicon,
    menuiconActive: activedashboardicon,
    role: ["super_admin", "administrator", "supervisor", "operator"],
    component: Dashboard,
  },
  {
    path: "contract-requests",
    name: "Contract Requests",
    acessName: "Contract Requests",
    menuicon: contractrequesticon,
    menuiconActive: activecontractrequesticon,
    role: ["super_admin", "administrator", "supervisor", "operator"],
    component: ContractRequests,
  },
  {
    path: "contract-management",
    name: "Contract Management",
    acessName: "Contract Management",
    menuicon: contractmanagementicon,
    menuiconActive: activecontractmanagementicon,
    role: ["super_admin", "administrator", "supervisor", "operator"],
    component: ContractManagement,
  },
  {
    path: "contract-modification-requests",
    name: "Contract Modification Requests",
    acessName: "Contract Modification Requests",
    menuicon: contractmodificationrequesticon,
    menuiconActive: activecontractmodificationrequesticon,
    role: ["super_admin", "administrator", "supervisor", "operator"],
    component: ContractModificationRequests,
  },
  {
    path: "customer-management",
    name: "Customer Management",
    acessName: "Customer Management",
    menuicon: customermanagementicon,
    menuiconActive: activecustomermanagementicon,
    role: ["super_admin", "administrator", "supervisor", "operator"],
    component: CustomerManagement,
  },
  {
    path: "driver-management",
    name: "Driver Management",
    acessName: "Driver Management",
    menuicon: drivermanagementicon,
    menuiconActive: activedrivermanagementicon,
    role: ["super_admin", "administrator", "supervisor", "operator"],
    component: DriverManagement,
  },
  {
    path: "user-management",
    name: "User Management",
    acessName: "User Management",
    menuicon: usermanagementicon,
    menuiconActive: activeusermanagementicon,
    role: ["super_admin", "administrator", "supervisor", "operator"],
    component: UserManagement,
  },
  {
    path: "reports",
    name: "Reports",
    acessName: "Reports",
    menuicon: reportsicon,
    menuiconActive: activereportsicon,
    role: ["super_admin", "administrator", "supervisor", "operator"],
    component: Reports,
  },
];

export default routs;
