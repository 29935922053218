import React from "react";
import searchicon from "../..//assets/images/searchicon.svg";
import notification from "../../assets/images/notification.svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { driverActions } from "../redux/driverSlice";
import { rolesActions } from "../../redux/rolesSlice";
import { userSliceActions } from "../../redux/userSlice";
import { useEffect } from "react";
import {
  getNotificationDashboard,
  deleteSingleNotification,
  dashboardActions,
  allClearNotification
} from "../../redux/dashboardSlice";
import closeImg from "../../assets/images/close-circle.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const UserPageHeader = ({ pageTtitle, SubTitle, toggle }) => {
  const dispatch = useDispatch();
  const dashState = useSelector((state) => state.dashboard);
  const [dropdownClick, setdropdownClick] = useState(false);

  useEffect(() => {
    dispatch(dashboardActions.setLoader(true));
    dispatch(getNotificationDashboard());
  }, [dashState.notDeleteFlag]);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  return (
    <>
      <div className="grid grid-cols-2 md:grid-cols-1 gap-6 mt-3 mb-10 ">
        <div className="col-span-1">
          <div className="flex text-2xl font-bold leading-9 text-header-title tracking-wider">
            {pageTtitle}
          </div>
          {SubTitle && <SubTitle />}
        </div>
        <div className="col-span-1">
          <div className="flex items-center justify-end md:justify-between">
            <div className="relative w-96">
              <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                <img src={searchicon} alt="" />
              </div>
              <input
                type="text"
                id="input-group-1"
                className="bg-bgcolor border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full pl-14 p-3.5 h-[39px]"
                onChange={(e) => {
                  if (toggle) {
                    setTimeout(
                      () => dispatch(rolesActions.setSearch(e.target.value)),
                      900
                    );
                  } else if (!toggle) {
                    // console.log(e.target.value)
                    setTimeout(
                      () =>
                        dispatch(userSliceActions.setSearch(e.target.value)),
                      900
                    );
                  }
                }}
                placeholder="Search"
              />
            </div>
            <div className="flex flex-col items-end justify-start">
              <button
                type="button"
                className="border font-medium rounded-lg text-sm  text-center inline-flex items-center p-1 ml-6"
              >
                <img
                  src={notification}
                  alt="Notification Icon"
                  className="h-7 w-7"
                  onClick={() => {
                    dispatch(getNotificationDashboard());
                    setdropdownClick(!dropdownClick);
                  }}
                />
              </button>
              <div className=" p-0 m-0">
                <div className="notification_wrap">
                  <div className={`dropdown ${dropdownClick ? "active" : ""} `}>
                    <div className="flex justify-between items-center ">
                      <p className="p-3 text-orange-500 font-bold text-xl">
                        Notifications
                      </p>
                      <p className="font-light text-md cursor-pointer" onClick={()=>{
                    dispatch(dashboardActions.setLoader(true))
                  dispatch(allClearNotification())
                }}>
                        Clear all
                      </p>
                    </div>
                    {dashState.loader ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Spin indicator={antIcon} />
                      </div>
                    ) : (
                      <div className="p-3 overflow-y-scroll scrollbar-color-dash">
                        {/* {console.log(dashState.notificationData)} */}
                        {dashState.notificationData.map((not) => (
                          <div className="notify_item pr-12" key={not.id}>
                            <div className="notify_info">
                              <div className="flex  items-center justify-between">
                                <p className="w-[350px]">{not.message}</p>
                                <img
                                  onClick={() => {
                                    dispatch(dashboardActions.setLoader(true));
                                    dispatch(deleteSingleNotification(not.id));
                                  }}
                                  className="w-[22px] cursor-pointer "
                                  src={closeImg}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserPageHeader;
