import React from "react";
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from "yup";
import TextError from "../../forms/TextError";
// import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../redux/authSlice";
import { Button, message, Space } from "antd";

const validationSchema = Yup.object({
  email: Yup.string()
    .required("email is required")
    
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces")
    .email("Please enter your valid Email Address"),
});


const ForgotPasswordModal = ({ setIsModalOpen, isModalOpen }) => {
  const initialValues = {
    email: "",
  };


  // const navigate = Navigate();
  const Navigate = useNavigate();
  const dispatch = useDispatch()
  const [messageApi, contextHolder] = message.useMessage();

  const success = (content) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };
  
  const error = (content) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };


  const onSubmit = (values, submitProps) => {
    let data = {};
    data.email = values.email;

    data.redirect_url = process.env.REACT_APP_BASE_URL

    // dispatch(forgotPassword(data.email))
// console.log(process.env.REACT_APP_BASE_URL)
    dispatch(forgotPassword(data))
    .then((response) => {
      // console.log(response)
      if(response.error?.message == "Rejected"){
        error(response.payload.message);
      }
      if (response.payload?.success == "False") {
        error(response.payload.message);
      }
      if (response.payload?.status == 200) {
        success("Link has been sent successfully");
      }
    })
    .catch((error) => {
      // console.log(error);
    });

  };
  return (
    <div className="flex flex-col justify-center items-center text-center">
            {contextHolder}
      <div className="py-10">
        <p className="text-sm font-bold">
          Enter your registred email address.
        </p>
        <p className="text-sm font-bold">
          we will send you a link to change password
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange={false}
          svalidateOnBlur={false}
        > 
          {(formik) => {
            return (
              <Form autoComplete="off">
                <hr />
                <div className="flex flex-col">
                  <div className="w-50">
                    <Field
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Email"
                      className="w-[300px] h-[37px] px-5 py-3 mt-10 bg-bginputfield border-none font-medium text-md text-form-input leading-6 rounded-lg driveronboarding-input"
                    />
                    <ErrorMessage name="email" component={TextError} />
                  </div>
                </div>
                <div className="flex justify-center mt-10">
                  <button
                    type="submit"
                    className="border border-black w-[300px]  lg:ml-5 my-1 text-white bg-primary border-black rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
                  >
                    Send
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
