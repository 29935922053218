import { ErrorMessage, Field, Formik, Form } from "formik";
import React, { useRef, useState,useEffect } from "react";
import * as Yup from "yup";
import TextError from "../TextError";
import { useSelector, useDispatch } from "react-redux";
import {
  createDriver,
  updateDriver,
  getAllDrivers,
  driverActions,
  getAllRegionsForDriver
} from "../../redux/driverSlice";
import { Button, message, Space,Spin } from "antd";

const FILE_SIZE = 2800 * 1024;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];


const DriverOnBoarding = ({
  editing,
  isModalOpen,
  setIsModalOpen,
  drivername,
  mobile_number,
  whatsapp_number,
  email_address,
  driver_photo,
  // registraion_number,
  vehicle_type,
  driver_license,
  vehicle_license,
  driver_type,
  status,
  region,
  card_holder_name,
  bank_account_number
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const driverState = useSelector((state) => state.driver);
  const initialValues = {
    drivername: drivername ? drivername : "",
    mobile_number: mobile_number ? mobile_number : "",
    whatsapp_number: whatsapp_number ? whatsapp_number : "",
    email_address: email_address ? email_address : "",
    driver_photo: driver_photo ? driver_photo : "",
    // registraion_number: registraion_number ? registraion_number : "",
    vehicle_type: vehicle_type ? vehicle_type : "",
    driver_license: driver_license ? driver_license : "",
    vehicle_license: vehicle_license ? vehicle_license : "",
    driver_type: driver_type ? driver_type : "",
    status: status ? status : "",
    region : region ? region.id : "" ,
    card_holder_name:card_holder_name ?card_holder_name : "",
    bank_account_number : bank_account_number ? bank_account_number : ""
  };


  const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const validationSchema = Yup.object({
  drivername: Yup.string()
    .required("Driver Name is required")
    .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
  email_address: Yup.string()
    .email("Please enter your valid Email Address"),
  mobile_number: Yup.string()
    .required("Mobile Number is required")
    .matches(phoneRegExp, "Mobile number is not valid")
    .min(8,"Cannot be less than 8 numbers")
    .max(8,"Cannot be more than 8 numbers"),
  whatsapp_number: Yup.string()
    .required("Whatsapp Number is required")
    .matches(phoneRegExp, "Whatsapp number is not valid")
    .min(8,"Cannot be less than 8 numbers")
    .max(8,"Cannot be more than 8 numbers"),
    driver_type: Yup.string()
    .required("Driver type is required"),
    status: Yup.string()
    .required("Status is required"),
    region: Yup.string()
    .required("Region  is required"),
    card_holder_name: Yup.string()
    .required("Card holder name is required"),
    bank_account_number: Yup.string()
    .required("Bank account number is required")
    .min(5, 'Provide minimum 5 numbers ')
    .max(20, 'Account number cannot exceed 20 digits'),
  // registraion_number: Yup.string()
  //   .required("Registration Number is required")
  //   .matches(/^(?!\s+$).*/, "This field cannot contain only blankspaces"),
  // driver_license: Yup.string().required("Driver's license is required"),
  driver_license: Yup.mixed()
    .test(
      "fileSize",
      "Uploaded file is too big.",
      (value) => !value || (value && value.size <= FILE_SIZE)
    )
    .test(
      "fileFormat",
      "Uploaded file has unsupported format.",
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
    ),
  vehicle_license: Yup.mixed()
    .nullable()
    .notRequired()
    .test(
      "fileSize",
      "Uploaded file is too big.",
      (value) => !value || (value && value.size <= FILE_SIZE)
    )
    .test(
      "fileFormat",
      "Uploaded file has unsupported format.",
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
    ),
  driver_photo: Yup.mixed()
    .nullable()
    .notRequired()
    .test(
      "fileSize",
      "Uploaded file is too big.",
      (value) => !value || (value && value.size <= FILE_SIZE)
    )
    .test(
      "fileFormat",
      "Uploaded file has unsupported format.",
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
    ),
});


  const [driverPhotoFileName, setDriverPhotoFileName] =
    useState("Upload photo");
  const [driverLicenseFileName, setDriverLicenseFileName] =
    useState("Upload license");
  const [vehicleLicenseFileName, setVehicleLicenseFileName] = useState(
    "Upload vehicle license"
  );
  const driverPhoto = useRef(null);
  const driverLicense = useRef(null);
  const vehicleLicense = useRef(null);
  const dispatch = useDispatch();
  // const driverState = useSelector(state=> state.driver)
  const handleDriverPhotoClick = (event) => {
    driverPhoto.current.click();
  };
  const handleDriverLicenseClick = (event) => {
    driverLicense.current.click();
  };
  const handleVehicleLicenseClick = (event) => {
    vehicleLicense.current.click();
  };
  const success = (content) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };

  const error = (content) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };

  const onSubmit = (values, submitProps) => {
    let data = {};
    data.drivername = values.drivername;
    data.mobile_number = values.mobile_number;
    data.whatsapp_number = values.whatsapp_number;
    data.email_address = values.email_address;
    data.driver_photo = values.driver_photo ? values.driver_photo : "";
    data.registraion_number = values.registraion_number;
    data.vehicle_type = values.vehicle_type;
    data.driver_license = values.driver_license ? values.driver_license : "";
    data.driver_type = values.driver_type;
    data.vehicle_license = values.vehicle_license ? values.vehicle_license : "";
    data.status = values.status?values.status:"";
    data.region = values.region? values.region:"";
    data.card_holder_name = values.card_holder_name ?values.card_holder_name : "";
    data.bank_account_number = values.bank_account_number ? values.bank_account_number : ""
  //  console.log(data)
    if (editing) {
      data.id = driverState.currentDriver.id;
      data.driver_license = values.driver_license ? values.driver_license : "";
      data.driver_photo = values.driver_photo ? values.driver_photo : "";
      data.vehicle_license = values.vehicle_license ? values.vehicle_license : "";
      // data.region =  values.region;
      if (data.driver_photo == "") {
        delete data.driver_photo;
      }
      if (data.driver_license == "") {
        delete data.driver_license;
      }
      if (data.vehicle_license == "") {
        delete data.vehicle_license;
      }
      // console.log(data)

      dispatch(updateDriver(data)).then((res) => {
        setIsModalOpen(false);
        if (res.payload[0]) {
          error(res.payload[0]);
        }

        if (res.payload.id) {
          submitProps.resetForm({values:''});
         
          success("Driver edited successfully");
        }
      });
    } else {
      
      // console.log(data)
      dispatch(driverActions.setAddDriverLoading())
     
      const ex = dispatch(createDriver(data))
        .then((data) => {
          setIsModalOpen(false);
          // console.log(ex)
          if (data.payload[0]) {
            error(data.payload[0]);
          }
          if (data.payload.request.status == 201) {
            setDriverLicenseFileName("Upload license")
      setDriverPhotoFileName("Upload photo")
      setVehicleLicenseFileName("Upload vehicle license")
            submitProps.resetForm();
        
            
           success("Driver created successfully")
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  // const dispatch = useDispatch()


  useEffect(()=>{
  dispatch(getAllRegionsForDriver())
  },[])

  // console.log(driverState.driverRegionList)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnChange={false}
      svalidateOnBlur={false}
      resetForm={true}

    >
      {(formik) => {
        return (
          <Form autoComplete="off">
            {contextHolder}
            <hr />
            <div className="grid grid-cols-5 gap-6 py-5">
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="drivername"
                  className="block text-xl font-medium leading-6 text-form-label"
                >
                  Name of the driver<span className="text-red-500">*</span>
                </label>
                <Field
                  type="text"
                  name="drivername"
                  id="drivername"
                  placeholder="Enter Name of Driver"
                  className="w-full px-5 py-3 my-2 bg-bginputfield border-none font-medium text-lg text-form-input leading-6 rounded-lg driveronboarding-input"
                />
                <ErrorMessage name="drivername" component={TextError} />
              </div>
              <div className="col-span-3 sm:col-span-6">
                <label
                  htmlFor="mobile_number"
                  className="block text-xl font-medium leading-6 text-form-label"
                >
                  Mobile number<span className="text-red-500">*</span>
                </label>
                <Field
                  type="number"
                  name="mobile_number"
                  id="mobile_number"
                  placeholder="Enter mobile number"
                  max
                  className="w-full px-5 py-3 my-2 bg-bginputfield border-none font-medium text-lg text-form-input leading-6 rounded-lg driveronboarding-input"
                />
                <ErrorMessage name="mobile_number" component={TextError} />
              </div>
              <div className="col-span-3 sm:col-span-6">
                <label
                  htmlFor="whatsapp_number"
                  className="block text-xl font-medium leading-6 text-form-label"
                >
                  Whatsapp number<span className="text-red-500">*</span>
                </label>
                <Field
                  type="number"
                  name="whatsapp_number"
                  id="whatsapp_number"
                  placeholder="Enter whatsapp "
                  className="w-full px-5 py-3 my-2 bg-bginputfield border-none font-medium text-lg text-form-input leading-6 rounded-lg driveronboarding-input"
                />
                <ErrorMessage name="whatsapp_number" component={TextError} />
              </div>
              <div className="col-span-3 sm:col-span-6">
                <label
                  htmlFor="email_address"
                  className="block text-xl font-medium leading-6 text-form-label"
                >
                  Email address
                </label>
                <Field
                  type="text"
                  name="email_address"
                  id="email_address"
                  placeholder="Email address"
                  className="w-full px-5 py-3 my-2 bg-bginputfield border-none font-medium text-lg text-form-input leading-6 rounded-lg driveronboarding-input"
                />
                <ErrorMessage name="email_address" component={TextError} />
              </div>
              
              <div className="col-span-3 sm:col-span-6">
                <label
                  htmlFor="driver_photo"
                  className="block text-xl font-medium leading-6 text-form-label"
                >
                  Driver's photo
                </label>
                <input
                placeholder="Driver Image"
                  type="file"
                  ref={driverPhoto}
                  id="driver_photo"
                  name="driver_photo"
                  className="hidden"
                  onChange={(e) => {
                    formik.setFieldValue("driver_photo", e.target.files[0]);
                    setDriverPhotoFileName(e.target.files[0].name);
                  }}
                />
                <div className="relative mb-6">
                  <div
                    className="w-full px-5 py-3 my-2 bg-bginputfield border-none font-medium text-lg text-form-input leading-6 rounded-lg"
                    onClick={handleDriverPhotoClick}
                  >
                    <span
                      className={`${
                        formik.values.driver_photo !== ""
                          ? "text-black"
                          : "text-gray-400"
                      }`}
                    >
                      {driverPhotoFileName}
                    </span>
                  </div>
                  <div className="absolute inset-y-0 right-5 flex items-center pl-3 pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <path
                        d="M14.7419 7.75877L8.17766 14.3231C7.37349 15.1272 6.28279 15.579 5.14552 15.579C4.00825 15.579 2.91755 15.1272 2.11338 14.3231C1.3092 13.5189 0.857422 12.4282 0.857422 11.2909C0.857422 10.1536 1.3092 9.06294 2.11338 8.25877L8.67766 1.69448C9.21378 1.15836 9.94091 0.857178 10.6991 0.857178C11.4573 0.857178 12.1844 1.15836 12.7205 1.69448C13.2566 2.2306 13.5578 2.95773 13.5578 3.71591C13.5578 4.47409 13.2566 5.20122 12.7205 5.73734L6.14909 12.3016C5.88103 12.5697 5.51747 12.7203 5.13838 12.7203C4.75929 12.7203 4.39572 12.5697 4.12766 12.3016C3.8596 12.0336 3.70901 11.67 3.70901 11.2909C3.70901 10.9118 3.8596 10.5483 4.12766 10.2802L10.1919 4.22305"
                        stroke="#29273A"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
        
                <ErrorMessage name="driver_photo" component={TextError} />
              </div>
              {/* <div className="col-span-3 sm:col-span-6">
                <label
                  htmlFor="registraion_number"
                  className="block text-xl font-medium leading-6 text-form-label"
                >
                  Vehicle registration number
                  <span className="text-red-500">*</span>
                </label>
                <Field
                  type="text"
                  name="registraion_number"
                  id="registraion_number"
                  placeholder="Enter registration number"
                  className="w-full px-5 py-3 my-2 bg-bginputfield border-none font-medium text-lg text-form-input leading-6 rounded-lg driveronboarding-input"
                />
                <ErrorMessage name="registraion_number" component={TextError} />
              </div> */}
              <div className="col-span-3 sm:col-span-6">
                <label
                  htmlFor="vehicle_type"
                  className="block text-xl font-medium leading-6 text-form-label"
                >
                  Type of vehicle
                </label>
                <Field
                  as="select"
                  name="vehicle_type"
                  id="vehicle_type"
                  className={`${
                    formik.values.vehicle_type !== ""
                      ? "driveronboarding-input"
                      : "font-medium"
                  } w-full px-5 py-2.5 my-2 bg-bginputfield border-none font-medium text-lg text-form-input leading-6 rounded-lg`}
                >
                  <option value="" disabled>
                    Vehicle type
                  </option>
                  <option className="driveronboarding-input" value="Classic">
                    Classic
                  </option>
                  <option className="driveronboarding-input" value="Van">
                    Van
                  </option>
                  <option className="driveronboarding-input" value="VIP">
                    VIP
                  </option>
                  <option className="driveronboarding-input" value="Luxury">
                    Luxury
                  </option>
                </Field>
              </div>
              <div className="col-span-3 sm:col-span-6">
                <label
                  htmlFor="driver_license"
                  className="block text-xl font-medium leading-6 text-form-label"
                >
                  Driver's license
                </label>
                <input
                  type="file"
                  ref={driverLicense}
                  id="driver_license"
                  name="driver_license"
                  className="hidden"
                  onChange={(e) => {
                    formik.setFieldValue("driver_license", e.target.files[0]);
                    setDriverLicenseFileName(e.target.files[0].name);
                  }}
                />
                <div className="relative mb-6">
                  <div
                    className="w-full px-5 py-3 my-2 bg-bginputfield border-none font-medium text-lg text-form-input leading-6 rounded-lg"
                    onClick={handleDriverLicenseClick}
                  >
                    <span
                      className={`${
                        formik.values.driver_license !== ""
                          ? "text-black"
                          : "text-gray-400"
                      }`}
                    >
                      {driverLicenseFileName}
                    </span>
                  </div>
                  <div className="absolute inset-y-0 right-5 flex items-center pl-3 pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <path
                        d="M14.7419 7.75877L8.17766 14.3231C7.37349 15.1272 6.28279 15.579 5.14552 15.579C4.00825 15.579 2.91755 15.1272 2.11338 14.3231C1.3092 13.5189 0.857422 12.4282 0.857422 11.2909C0.857422 10.1536 1.3092 9.06294 2.11338 8.25877L8.67766 1.69448C9.21378 1.15836 9.94091 0.857178 10.6991 0.857178C11.4573 0.857178 12.1844 1.15836 12.7205 1.69448C13.2566 2.2306 13.5578 2.95773 13.5578 3.71591C13.5578 4.47409 13.2566 5.20122 12.7205 5.73734L6.14909 12.3016C5.88103 12.5697 5.51747 12.7203 5.13838 12.7203C4.75929 12.7203 4.39572 12.5697 4.12766 12.3016C3.8596 12.0336 3.70901 11.67 3.70901 11.2909C3.70901 10.9118 3.8596 10.5483 4.12766 10.2802L10.1919 4.22305"
                        stroke="#29273A"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <ErrorMessage name="driver_license" component={TextError} />
              </div>
              <div className="col-span-3 sm:col-span-6">
                <label
                  htmlFor="vehicle_license"
                  className="block text-xl font-medium leading-6 text-form-label"
                >
                  Motor vehicle license
                </label>
                <input
                  // className='font-bold'
                  type="file"
                  ref={vehicleLicense}
                  id="vehicle_license"
                  name="vehicle_license"
                  className="hidden font-bold text-xs "
                  onChange={(e) => {
                    formik.setFieldValue("vehicle_license", e.target.files[0]);
                    setVehicleLicenseFileName(e.target.files[0].name);
                  }}
                />
                <div className="relative mb-6">
                  <div
                    className="w-full px-5 py-3 my-2 bg-bginputfield border-none font-medium text-xl text-form-input leading-6 rounded-lg"
                    onClick={handleVehicleLicenseClick}
                  >
                    <span
                      className={`${
                        formik.values.vehicle_license !== ""
                          ? "text-black"
                          : "text-gray-400"
                      }`}
                    >
                      {vehicleLicenseFileName}
                    </span>
                  </div>
                  <div className="absolute inset-y-0 right-5 flex items-center pl-3 pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <path
                        d="M14.7419 7.75877L8.17766 14.3231C7.37349 15.1272 6.28279 15.579 5.14552 15.579C4.00825 15.579 2.91755 15.1272 2.11338 14.3231C1.3092 13.5189 0.857422 12.4282 0.857422 11.2909C0.857422 10.1536 1.3092 9.06294 2.11338 8.25877L8.67766 1.69448C9.21378 1.15836 9.94091 0.857178 10.6991 0.857178C11.4573 0.857178 12.1844 1.15836 12.7205 1.69448C13.2566 2.2306 13.5578 2.95773 13.5578 3.71591C13.5578 4.47409 13.2566 5.20122 12.7205 5.73734L6.14909 12.3016C5.88103 12.5697 5.51747 12.7203 5.13838 12.7203C4.75929 12.7203 4.39572 12.5697 4.12766 12.3016C3.8596 12.0336 3.70901 11.67 3.70901 11.2909C3.70901 10.9118 3.8596 10.5483 4.12766 10.2802L10.1919 4.22305"
                        stroke="#29273A"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <ErrorMessage name="vehicle_license" component={TextError} />
              </div>

             
              <div className="col-span-3 sm:col-span-6">
                <label
                  htmlFor="status"
                  className="block text-xl font-medium leading-6 text-form-label"
                >
                  Driver Type <span className="text-red-500">*</span>
                </label>
                <Field
                  as="select"
                  name="driver_type"
                  id="driver_type"
                  className={`${
                    formik.values.driver_type !== ""
                      ? "driveronboarding-input"
                      : "font-medium"
                  } w-full bg-bginputfield px-5 py-2.5 my-2  border-none font-medium text-lg text-form-input leading-6 rounded-lg`}
                >
                  <option value="" disabled>
                    Driver Type 
                  </option>
                  <option
                    className="py-[3rem] driveronboarding-input"
                    value="Male"
                  >
                    Male
                  </option>
                  <option className="driveronboarding-input" value="Female">
                    Female
                  </option>
                </Field>
                <ErrorMessage name="driver_type" component={TextError} />
              </div>


              <div className="col-span-3 sm:col-span-6 ">
                <label
                  htmlFor="card_holder_name"
                  className="block text-xl font-medium leading-6 text-form-label"
                >
                  Card holder name <span className="text-red-500">*</span>
                </label>
                <Field
                  type="text"
                  name="card_holder_name"
                  id="card_holder_name"
                  placeholder="Enter card holder name"
                  className="w-full px-5 py-3 my-2 bg-bginputfield border-none font-medium text-lg text-form-input leading-6 rounded-lg driveronboarding-input"
                >
                 
                </Field>
                <ErrorMessage name="card_holder_name" component={TextError} />
              </div>


              <div className="col-span-3 sm:col-span-6 ">
                <label
                  htmlFor="bank_account_number"
                  className="block text-xl font-medium leading-6 text-form-label"
                >
                  Bank account number <span className="text-red-500">*</span>
                </label>
                <Field
                  type="number"
                  name="bank_account_number"
                  id="bank_account_number"
                  placeholder="Bank account number"
                  className="w-full px-5 py-3 my-2 bg-bginputfield border-none font-medium text-lg text-form-input leading-6 rounded-lg driveronboarding-input"
                >
                  
                </Field>
                <ErrorMessage name="bank_account_number" component={TextError} />
              </div>

              {/* <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="driverregion"
                  className="block text-xl font-medium leading-6 text-form-label"
                >
                 Region <span className="text-red-500">*</span>
                </label>
                <Field
                  type="text"
                  name="f"
                  id="f"
                  placeholder="Enter Name of Driver"
                  className="w-full px-5 py-3 my-2 bg-bginputfield border-none font-medium text-lg text-form-input leading-6 rounded-lg driveronboarding-input"
                />
                <ErrorMessage name="f" component={TextError} />
              </div> */}
               <div className="col-span-3 sm:col-span-6 ">
                <label
                  htmlFor="status"
                  className="block text-xl font-medium leading-6 text-form-label"
                >
                  Status <span className="text-red-500">*</span>
                </label>
                <Field
                  as="select"
                  name="status"
                  id="status"
                  className={` w-full ${
                    formik.values.status !== ""
                      ? "driveronboarding-input"
                      : "font-medium"
                  }  bg-bginputfield px-5 py-2.5 my-2 border-none font-medium text-lg text-form-input leading-6 rounded-lg`}
                >
                  <option value="" disabled>
                    Select status
                  </option>
                  <option className="driveronboarding-input" value="Active">
                    Active
                  </option>
                  <option className="driveronboarding-input" value="Inactive">
                    Inactive
                  </option>
                </Field>
                <ErrorMessage name="status" component={TextError} />
              </div>
               <div className="col-span-3 sm:col-span-6">
                <label
                  htmlFor="region"
                  className="block text-xl font-medium leading-6 text-form-label"
                >
                  Region <span className="text-red-500">*</span>
                </label>
                <Field
                  as="select"
                  name="region"
                  id="region"
                  className={`${
                    formik.values.region !== ""
                      ? "driveronboarding-input"
                      : "font-medium"
                  } w-full px-5 py-2.5 my-2 bg-bginputfield border-none font-medium text-xl text-form-input leading-6 rounded-lg`}
                >
                  <option value="" disabled defaultValue className="font-bold">
                    Region
                  </option>
                  {/* {console.log()} */}
                  {driverState.driverRegionList.map((rol) => (
                    <option className="driveronboarding-input" value={+rol.id}>
                      {rol.name}
                    </option>
                  ))}
                  {/* <option value="Admin">Admin</option> */}
                </Field>
                <ErrorMessage name="region" component={TextError} />
              </div>
            </div>
            <div className="col-span-6 sm:col-span-6">
           
       <div className="flex gap-3 items-center justify-center flex-wrap">
      <button
        type="button"
        className="w-[200px]  lg:ml-5 my-1 text-primary hover:bg-primary hover:text-white hover:border-primary border-2 border-black rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
        onClick={() => setIsModalOpen(!isModalOpen)}
      >
        Cancel
      </button>
      <button
        type="submit"
        className="w-[200px]  lg:ml-5 my-1 text-primary hover:bg-primary hover:text-white hover:border-primary border-2 border-black rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
      >
        {editing ? "Update" : "Add"}
      </button>
    </div>
            
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DriverOnBoarding;
