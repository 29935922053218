import React, { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import PageHeader from "../../layout/PageHeader";
import driver_img from "../../assets/images/driver_img.png";
import { useSelector,useDispatch } from "react-redux";
import { Select,DatePicker } from "antd";
import "../DriverManagement/drivermanagement.scss";
import CustomerOrder from "./CustomerOrder";
import { getAllOrdersofSelectedCustomer,customerSliceActions ,getAllOrdersofSelectedCustomerFiltered} from "../../redux/customerSlice";
// import driver_img from "../../assets/images/driver_img.png";
import CustomerHeaderWOS from "./CustomerHeaderWOH";
import { tConvert } from "../../common/timeConverter";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
const CustomerDetails = ({ driverId }) => {
  const customerState = useSelector(state=>state.customer)
  const [loader,setLoader] = useState(true)
  const dispatch = useDispatch()
  const SubTitle = () => {
    return (
      <div className="flex items-center my-2">
        <Link
          to="/admin/customer-management"
          className="leading-5 text-base font-medium text-breadcrumb hover:text-black"
        >
          Customer management
        </Link>
        <div className="inline-flex items-center justify-center w-2 h-2 mx-3 bg-breadcrumb border-breadcrumb rounded-full "></div>
        <span className="leading-5 text-base font-medium text-header-title">
          Customer details
        </span>
      </div>
    );
  };

  const data = [
    {
      key: 1,
      title: "One Way Trip",
      id: 1011101,
      startingDate: "11/12/2022",
      startingTime: "10:22 am",
      pickupLocation1: "2346 unknown road",
      pickupLocation2: "westnlwire, arizona",
      dropOffLocation1: "2346 unknown road",
      dropoffLocation2: "westnlwire, arizona",
      payment: true,
    },
    {
      key: 2,
      title: "One Way Trip",
      id: 1011102,
      startingDate: "11/12/2022",
      startingTime: "10:22 am",
      pickupLocation1: "2346 unknown road",
      pickupLocation2: "westnlwire, arizona",
      dropOffLocation1: "2346 unknown road",
      dropoffLocation2: "westnlwire, arizona",
      payment: true,
    },
    {
      key: 3,
      title: "One Way Trip",
      id: 1011103,
      startingDate: "11/12/2022",
      startingTime: "10:22 am",
      pickupLocation1: "2346 unknown road",
      pickupLocation2: "westnlwire, arizona",
      dropOffLocation1: "2346 unknown road",
      dropoffLocation2: "westnlwire, arizona",
      payment: true,
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const {email,first_name,id,last_name,mobile_number,profile,watsap_number,is_active} = customerState.selectedCustomer

//   useEffect(()=>{
// dispatch(getAllOrdersofSelectedCustomer(id)).then(res=>console.log(res))
//   },[])

useEffect(()=>{
  const data = {
    id,
    orderDate:customerState.orderDate,
    orderTrip_Type:customerState.orderTrip_Type
  }
  
  dispatch(getAllOrdersofSelectedCustomerFiltered(data)).then((res)=>console.log(res))
},[customerState.orderDate,customerState.orderTrip_Type])


useEffect(()=>{
 dispatch(customerSliceActions.clearAllFilters())
},[])


const mapData = customerState.orderTrip_Type != "" || customerState.orderDate != "" ? customerState.filteredCustomerOrders : customerState.allCustomerOrdersList
// console.log(customerState.filteredCustomerOrders)
setTimeout(()=>setLoader(false),800)
  return (

    loader?   <div className="flex justify-center items-center h-[100vh]">
      <Spin indicator={antIcon} />
    </div> :
    <div className="m-10">
      <CustomerHeaderWOS pageTtitle="Customer Details" SubTitle={SubTitle} />
      <div>
        <div className="flex justify-between  bg-white rounded-3xl mb-8 gap-12 p-6 ">
          <div className="flex gap-8  w-[70%]">
            <div   style={{
                height: "180px",
                width: "180px",
                backgroundColor: "black",
                borderRadius: "20px",
              }}>
              <img   style={{
                  height: "180px",
                  width: "180px",
                  objectFit: "contain",
                }} className="min-w-[7rem] max-w-[20rem]" src={profile?profile:driver_img} alt="driver" />
                {/* {console.log(profile)} */}
            </div>
            <div className=" flex flex-col justify-between text-secondary w-[80%] lg:w-[70%] ">
              <h3 className="text-lg font-bold mb-4">{id}</h3>
              <h2 className="text-2xl font-bold -mt-5 mb-5">{first_name+" "+last_name}</h2>
              <div className="flex justify-between w-full gap-5 items-align">
                <div>
                  <p className="mb-1 text-xs ">Contact number </p>
                  <h3 className="text-lg font-bold">{mobile_number}</h3>
                </div>
                <div>
                  <p className="mb-1 text-xs ">Whatsapp number </p>
                  <h3 className="text-lg font-bold">{watsap_number}</h3>
                </div>
                <div>
                  <p className="mb-1 text-xs ">Email ID </p>
                  <h3 className="text-lg font-bold">{email}</h3>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className={`px-10 py-1 text-sm  rounded-lg ${is_active ? "bg-active" :"bg-red-300"}`}>{is_active ? "Active" :"Inactive"}</p>
          </div>
        </div>

        <div className="p-6 bg-white rounded-3xl mb-8">
        <div className="grid grid-cols-2 md:grid-cols-1 gap-6 my-5">
        <div className="col-span-1">
          <p className="font-bold text-lg leading-6 text-header-title my-2">
          Orders
          </p>
        </div>
        <div className="col-span-1 flex items-center md:justify-between justify-end flex-wrap gap-3">
          {/* <div className="my-2 flex items-center justify-between md:w-full w-1/2"> */}
          <Select
            allowClear
            className="w-[200px]  my-1 font-bold border-none bg-[#f265220d] rounded-md"
            defaultValue="Type"
            onChange={(val)=>dispatch(customerSliceActions.setOrderType(val))}
            bordered={false}
            options={[
              {
                value: "",
                label: "All",
                className:
                  "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
              },
              {
                value: "Day%20Trip",
                label: "Day trip",
                className:
                  "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
              },
              {
                value: "Contract%20Trip",
                label: "Contract trip",
                className: "w-full py-[.5rem] text-xs font-bold",
              },
            ]}
          />
            <DatePicker format="DD/MM/YYYY" onChange={(str,val)=>{
                let newdate = val.split("/").reverse().join("-");
              dispatch(customerSliceActions.setDate(newdate))}} />

          {/* </div> */}
        </div>
        <div className="flex items-center justify-between flex-wrap"></div>
        {/* {console.log(customerState.allCustomers)} */}
      </div>
      {/* {console.log(customerState.allCustomerOrdersList)} */}
          {mapData.map((trip, index) => {
            return (
              <CustomerOrder
              arrow= {true}
                _id={trip.id}
                key={trip.id}
                title={trip.trip_type}
                id={trip.trip_id}
                startdate={trip.start_date}
                starttime={tConvert(trip.start_time)}
                pickuploc1={trip.pickup_point?.adress}
                // pickuploc2={trip.pickup_point.}
                dropoffloc1={trip.drop_of_point?.adress}
                // dropoffloc2={trip.dropoffLocation2}
                payment={trip.trip_payment?.status}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;
