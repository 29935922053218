import React from "react";
import "./sidebar.scss";
import routs from "../routes/routes";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../assets/images/otaxi-logo.svg";
import arrowopen from "../assets/images/arrowopen.svg";
import arrowclose from "../assets/images/arrowclose.svg";
import avatar from "../assets/images/avatar.svg";
import { useDispatch,useSelector } from "react-redux";
import { authActions } from "../redux/authSlice";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ open, setOpen }) => {
  let path = useLocation();
  let dispatch = useDispatch();
  const authState = useSelector(state=>state.auth)
  const pathArray = path.pathname.split("/");
  let navigate = useNavigate();
  let acessAllowedMenus = [routs[0]]
  // console.log(authState.acessMenus)
  for(let i= 0;i<routs.length;i++){
    for(let j=0;j<authState.acessMenus.length;j++){
      if(routs[i].acessName == authState.acessMenus[j].name){
          acessAllowedMenus.push(routs[i])
      }
    }
  }

  // console.log(acessAllowedMenus)
  return (
    <div className={open ? "sidebar-wrap " : "sidebar-wrap close"}>
      <div className="flex flex-col py-5 px-2 items-center w-full overflow-auto	">
        <div className={`flex  items-center w-full justify-around relative`}>
          <img
            src={logo}
            alt="Otaxi Logo"
            className={` ${open ? "w-auto ml-11" : "w-12"} my-3 `}
          />
          <button
            type="button"
            className={`flex text-white cursor-pointer   ${
              open ? "w-7 mt-2" : "w-6 mt-0"
            } `}
            onClick={() => setOpen(!open)}
          >
            <img src={open ? arrowclose : arrowopen} alt="Collapse Arrow" />
          </button>
        </div>
        <img src={avatar} alt="Avatar" className="w-auto my-3" />
        {open && (
          <>
            <h2 className="my-3 text-white text-lg font-bold leading-6">
              {authState.user_name}
            </h2>
           
          </>
        )}
      </div>
      <div className="menu-wrap ">
        <ul className="">
          {acessAllowedMenus.map((menu, index) => {
        
            return (
              <li key={index} className="">
        
                <NavLink
                  to={menu.path}
                  className={`font-xs menuitem flex rounded-lg hover:bg-primary py-3 px-3 my-3 cursor-pointer items-center gap-x-4 font-medium  text-sm leading-6  ${
                    pathArray.includes(menu.path)
                      ? "font-extrabold text-white "
                      : "text-menu-text font-sm"
                  } `}
                >
                  <div className="flex w-[50px] items-center justify-center">
                    <img
                      src={
                        pathArray.includes(menu.path)
                          ? menu.menuiconActive
                          : menu.menuicon
                      }
                      alt={menu.name}
                    />
                  </div>
                  {/* <div className="flex names"> */}
                  <div className={`names ${!open ? "hidden" : "show"}`}>
                    <span className={`origin-left duration-200`}>
                      {menu.name}
                    </span>
                  </div>
                </NavLink>
              </li>
            );
          })}
        </ul>
        {open && (
          <button
            className="px-6 py-2 text-white text-lg"
            onClick={() => {
              dispatch(authActions.logout());
              navigate("/");
            }}
          >
            Logout
          </button>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
