import React from "react";



const ContractModHeaderWOS = ({ pageTtitle, SubTitle }) => {


  return (
    <>
      <div className="grid grid-cols-2 md:grid-cols-1 gap-6 mt-3 mb-10 ">
        <div className="col-span-1">
          <div className="flex text-2xl font-bold leading-9 text-header-title tracking-wider">
            {pageTtitle}
          </div>
          {SubTitle && <SubTitle />}
        </div>
        
      </div>
    </>
  );
};

export default ContractModHeaderWOS;
