import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteDriver } from "../../redux/driverSlice";
import { Button, message, Space } from "antd";

const DeleteDriver = ({ id, setIsModalOpen, isModalOpen,setDeleteRefresh ,deleteRefresh}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Driver deleted successfully",
    });
  };

  const error = (content) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };

  const deleteCurrentDriver = () => {
    dispatch(deleteDriver(id)).then((res) => {
      if(res.error?.message == "Rejected"){
        setIsModalOpen(false);
        error(res.payload?.error);
        
      } else {
        setIsModalOpen(false);
        setDeleteRefresh(!deleteRefresh)
        success();
      }
     
    });
  };

  return (
    <div className="flex flex-col justify-center items-center">
      {contextHolder}
      <div className="py-10">
        <p className="text-xl font-bold">Are you sure want to delete ?</p>
      </div>
      <div className="flex justify-center ">
        <button
          onClick={() => setIsModalOpen(!isModalOpen)}
          className="border border-black w-[150px] mr-3  lg:ml-5 my-1 text-primary   border-black rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
        >
          Cancel
        </button>
        <button
          onClick={deleteCurrentDriver}
          className="border border-black w-[150px]  lg:ml-5 my-1 text-white bg-primary border-black rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
        >
          Yes
        </button>
      </div>
    </div>
  );
};

export default DeleteDriver;
