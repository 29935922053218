import { Route, Routes } from "react-router-dom";
import "./App.css";
import DriverDetails from "./components/DriverManagement/DriverDetails";
import AdminDashboard from "./layout/AdminDashboard";
import routs from "./routes/routes";
import { useSelector } from "react-redux";
import CustomerDetails from "./components/CustomerManagement/CustomerDetails";
import TripDetails from "./components/CustomerManagement/TripDetails";
import ContractRescheduleRequest from "./components/ContractModifications/ContractRescheduleRequest";
import ContractTripDetails from "./components/ContractManagement/ContractTripDetails";
import ContractReqTripDetails from "./components/ContractRequests/ContractReqTripDetails";
import Login from "./pages/Login";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import PublicRoutes from "./routes/PublicRoutes";
import ResetPassword from "./pages/ResetPassword";
import DriverContractTripDetails from "./components/DriverManagement/DriverContractTripDetails";
import ContractDriverPage from "./components/ContractManagement/ContractDriverPage";
import ContractModificationDriverPage from "./components/ContractModifications/ContractModificationDriverPage";
import CustomerDriverPage from "./components/CustomerManagement/CustomerDriverDetails";
import Dashboard from "./pages/Dashboard";
import ContractRequests from "./pages/ContractRequests";
import ContractManagement from "./pages/ContractManagement";
import ContractModificationRequests from "./pages/ContractModificationRequests";
import CustomerManagement from "./pages/CustomerManagement";
import DriverManagement from "./pages/DriverManagement";
import UserManagement from "./pages/UserManagement";
import Reports from "./pages/Reports";
import { useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authActions } from "./redux/authSlice";
// import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";


function App() {
 
  const authState = useSelector((state) => state.auth);
  const driverState = useSelector((state) => state.driver);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  
  // useEffect(()=>{
  //   console.log("hii")
  //   console.log(authState.loggedIn)
  //   if(authState.loggedIn == false){
  //     navigate("/")
  //   }
  // },[])




useLayoutEffect(()=>{
  if(window.location.pathname == "admin/forgot-password/:id/:token/"){
    console.log()
      dispatch(authActions.logout())
  }
},[])

  return (
    <div className="w-[100%]">
     <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route path="/admin" element={<AdminDashboard />} basename="/admin">
            {/* {routs.map((item, index) => {
              return (
                <Route
                  key={index}
                  path={item.path}
                  element={<item.component />}
                />
              );
            })} */}

           

            <Route path="dashboard" element={<Dashboard />} />
          
         {authState.contract_request_flag &&    <Route path="contract-requests" element={<ContractRequests />} />}

          {authState.contract_management_flag &&   <Route
              path="contract-management"
              element={<ContractManagement />}
            />}

           {authState.contract_modification_flag &&  <Route
              path="contract-modification-requests"
              element={<ContractModificationRequests />}
            />}

            {authState.customer_flag && <Route
              path="customer-management"
              element={<CustomerManagement />}
            />}

          {authState.driver_flag &&   <Route path="driver-management" element={<DriverManagement />} />}

           {authState.user_flag &&  <Route path="user-management" element={<UserManagement />} />}

            {authState.report_flag && <Route path="reports" element={<Reports />} />}

            <Route
              path="driver-management/driver-details/:id"
              element={
                <DriverDetails
                  driverId={driverState.currentDriverId}
                  driver_ID={driverState.currentDriver_ID}
                />
              }
            />
            <Route
              path="customer-management/customer-details/:id"
              element={<CustomerDetails />}
            />
            <Route
              path="customer-management/customer-details/:id/orderdetails/:oid"
              element={<TripDetails />}
            />
            <Route
              path="contract-modification-requests/contract-request/:id"
              element={<ContractRescheduleRequest />}
            />
            <Route
              path="contract-management/contract-details/:id"
              element={<ContractTripDetails />}
            />
            <Route
              path="contract-requests/trip-details/:id"
              element={<ContractReqTripDetails />}
            />
            <Route
              path="driver-management/driver-details/:id/trip-details/:id"
              element={<DriverContractTripDetails />}
            />
            <Route
              path="contract-management/contract-details/:id/driverDetails"
              element={<ContractDriverPage />}
            />
            <Route
              path="contract-modification-requests/contract-request/:id/driverDetails"
              element={<ContractModificationDriverPage />}
            />
            <Route
              path="customer-management/customer-details/:id/orderdetails/:id/driverDetails"
              element={<CustomerDriverPage />}
            />
          </Route>
        </Route>
              
        <Route element={<PublicRoutes />}>
          
          <Route exact path="/" element={<Login />} />
          <Route
            exact
            path="admin/forgot-password/:id/:token/"
            element={<ResetPassword />}
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
