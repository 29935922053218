import React, { useEffect } from "react";
// import CustomerPageHeader from "../components/CustomerManagement/CustomerPageHeader";
import DashboardBox from "../components/Dashboard/DashboardBox";
import { DatePicker } from "antd";
import notificationStatus from ".././assets/images/notification-status.svg";
import tick from ".././assets/images/clipboard-tick.svg";
import note from ".././assets/images/note-2.svg";
import user from ".././assets/images/user-tick.svg";
import send from ".././assets/images/send-2.svg";
import people from ".././assets/images/people.svg";
import noteRemove from ".././assets/images/note-remove.svg";
import clipboard from ".././assets/images/clipboard-close.svg";
import document from ".././assets/images/document-previous.svg";
import ContractModHeaderWOS from "../components/ContractModifications/ContractModHeaderWOS";
import calendar from "../assets/images/calendar.svg"
import { useDispatch,useSelector } from "react-redux";
import {  getAllDriversRevenue, getAllUsersRevenue, getNotificationDashboard } from "../redux/dashboardSlice";
import { getAllDashboardData } from "../redux/dashboardSlice";
import { authActions } from "../redux/authSlice";
import { useNavigate } from "react-router-dom";
import { clearAllContractManagement, clearAllContractMod, clearAllContractRequest, clearAllCustomer, clearAllDriver, clearAllReports, clearAllRoles, clearAllUsers } from "../common/clearfunction";
import DashboardPageHeader from "../components/Dashboard/DashboardHeader";

export const logoutFromDB = () =>{
  
}

const Dashboard = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardState = useSelector(state=>state.dashboard)

useEffect(()=>{
  clearAllContractRequest()
  clearAllContractManagement()
  clearAllContractMod()
  clearAllCustomer()
  clearAllDriver()
  clearAllUsers()
  clearAllRoles()
  clearAllReports()
  dispatch(getAllDashboardData("hi"))
  dispatch(getNotificationDashboard())
    dispatch(getAllUsersRevenue())
  dispatch(getAllDriversRevenue()).catch((err)=>console.log(err))
  // console.log(dashboardState)
},[])




  return (
    <div className="mx-10  flex flex-col w-[93%]">
      <DashboardPageHeader pageTtitle="Dashboard" />
      <div className="flex gap-4">
        <div className="w-[30%]">
          <div className="  rounded-3xl bg-dashboard bg-contain bg-no-repeat  flex flex-col items-center h-[100%] w-[100%] bg-cover">
            <p className="text-4xl font-bold text-white pt-10 mt-7">
              Good Morning
            </p>
            <p className="text-white text-xl mt-3">Have a nice day</p>
          </div>
        </div>
        <div className="w-[70%]">
          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-1">
              <DashboardBox
                icon={notificationStatus}
                value={dashboardState?.allData?.total_contract_request}
                title="Total Contracts Requests"
                bgcolor="#f26522"
              />
            </div>
            <div className="col-span-1">
              <DashboardBox
                icon={tick}
                value={dashboardState?.allData?.total_contract_approved}
                title="Total Contracts Approved"
              />
            </div>
            <div className="col-span-1">
              <DashboardBox
                icon={note}
                value={dashboardState?.allData?.total_order_confirmed}
                title="Total Orders Confirmed"
              />
            </div>
            <div className="col-span-1">
              <DashboardBox icon={user} value={dashboardState?.allData?.total_customers} title="Total Customers" />
            </div>
            <div className="col-span-1">
              <DashboardBox icon={send} value={dashboardState?.allData?.total_trips} title="Total Trips" />
            </div>
            <div className="col-span-1">
              <DashboardBox icon={people} value={dashboardState?.allData?.total_drivers} title="Total Drivers" />
            </div>
            <div className="col-span-1">
              <DashboardBox
                icon={noteRemove}
                value={dashboardState?.allData?.total_contract_declined}
                title="Total Contracts Declined"
              />
            </div>
            <div className="col-span-1">
              <DashboardBox
                icon={clipboard}
                value={dashboardState?.allData?.total_contract_canceled}
                title="Total Cancelled Contracts"
              />
            </div>
            <div className="col-span-1">
              <DashboardBox icon={document} value={dashboardState?.allData?.total_contract_refused} title="Total Refused" />
            </div>
          </div>
        </div>
      </div>

      <div className="flex  justify-between pt-7">
        <div className="flex justify-between  bg-white rounded-3xl mb-8 gap-12 p-6 w-[48%]">
          <div className="flex gap-8  w-[100%]">
            <div className="flex flex-col item-center w-[100%]  ">
              <div className="flex justify-between w-[100%] ">
                <p className="font-bold text-xl text-orange-500">Revenue</p>
                <DatePicker format="DD/MM/YYYY" onChange={(str,val)=>{
                     let newdate = val.split("/").reverse().join("-");
                  dispatch(getAllUsersRevenue(newdate))}} />
              </div>
              <p>Users</p>
              <div className="flex justify-between pt-7 pb-3 text-sm border-b-2 border-dotted">
                <p className="text-zinc-500 font-bold">Total Customers</p>
                <div>
                  <p className="text-zinc-500 font-bold">Revenue</p>
                  <p className="text-sm text-zinc-400">Per month</p>
                </div>
                <div className="mr-10">
                  <p className="text-zinc-500 font-bold">Revenue</p>
                  <p className="text-sm text-zinc-400">Per day</p>
                </div>
              </div>
              <div className="flex justify-between pt-4 ml-10 ">
                <p className=" ">{dashboardState?.usersData?.total_customers}</p>
                <div>
                  <p className="">{dashboardState?.usersData?.monthly_revenew}  OMR</p>
                </div>
                <div className="mr-10">
                  <p className="">{dashboardState?.usersData?.daily_revenew} OMR</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between  bg-white rounded-3xl mb-8 gap-12 p-6 w-[48%] ">
          <div className="flex gap-8  w-[100%]">
            <div className="flex flex-col item-center w-[100%] ">
              <div className="flex justify-between w-[100%] " >
                <p className="font-bold text-xl text-orange-500">Revenue</p>
                <DatePicker format="DD/MM/YYYY" onChange={(str,val)=>{
                     let newdate = val.split("/").reverse().join("-");
                  dispatch(getAllDriversRevenue(newdate))}} />
              </div>
              <p>Drivers</p>
              <div className="flex justify-between pt-7 pb-3 text-sm  border-b-2 border-dotted">
                <p className="text-zinc-500 font-bold">Total Drivers</p>
                <div>
                  <p className="text-zinc-500 font-bold">Revenue</p>
                  <p className="text-sm text-zinc-400">Per month</p>
                </div>
                <div className="mr-10">
                  <p className="text-zinc-500 font-bold">Revenue</p>
                  <p className="text-sm text-zinc-400">Per day</p>
                </div>
              </div>
              <div className="flex justify-between pt-4 ml-10">
                <p className=" ">{dashboardState?.driversData?.total_drivers}</p>
                <div>
                  <p className="">{dashboardState?.driversData?.monthly_revenew} OMR</p>
                </div>
                <div className="mr-10">
                  <p className="">{dashboardState?.driversData?.daily_revenew} OMR</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
