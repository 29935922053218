import React, { useState } from "react";
import { Link } from "react-router-dom";
import CustomerPageHeader from "../CustomerManagement/CustomerPageHeader";
import { useSelector, useDispatch } from "react-redux";
import { acceptTrip } from "../../redux/contractRequestsSlice";
import ModalLayout from "../../common/ModalLayout";
import { rejectTrip } from "../../redux/contractRequestsSlice";
import DriverHeaderWOS from "./DriverHeaderWOS";
import { tConvert } from "../../common/timeConverter";

const DriverContractTripDetails = () => {
  const driverState = useSelector((state) => state.driver);
  const dispatch = useDispatch();
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [rejectModal, setRejectModal] = useState(false);
//   const [rejectReason, setRejectReason] = useState("");

//   const showModal = () => {
//     setIsModalOpen(true);
//   };
//   const showRejectModal = () => {
//     setRejectModal(true);
//   };
// console.log(driverState.selectedContract)

  const SubTitle = () => {
    return (
      <div className="flex items-center my-2">
        <Link
          to="/admin/driver-management"
          className="leading-5 text-base font-medium text-breadcrumb hover:text-black black-text"
        >
          Driver Management
        </Link>

        <div className="inline-flex items-center justify-center w-2 h-2 mx-3 bg-breadcrumb border-breadcrumb rounded-full "></div>

        <span className="leading-5 text-base font-medium text-header-title font-lg text-lg">
          Driver details
        </span>

        <div className="inline-flex items-center justify-center w-2 h-2 mx-3 bg-breadcrumb border-breadcrumb rounded-full "></div>

        <span className="leading-5 text-base font-medium text-header-title font-lg text-lg">
          Trip details
        </span>
      </div>
    );
  };
  return (
    <div className="m-10">
      <DriverHeaderWOS pageTtitle="Trip Details" SubTitle={SubTitle} />
      <div className="flex justify-between  bg-white rounded-3xl mb-8 gap-12 p-6 ">
        <div className="flex   w-[100%]">
          <div className="flex flex-col item-center w-[100%] ">
            <div className="flex justify-between w-[100%] pb-4">
              <div>
                <p className="text-xl font-extrabold text-orange-500	">
                  {driverState.selectedContract.trip_type == "Day Trip"
                    ? driverState.selectedContract.day_trip_type
                    : driverState.selectedContract.trip_type}
                </p>
                {driverState.selectedContract.trip_type ==
                  "Contract Trip" && (
                  <p className="text-sm ">
                    Contract name :{" "}
                    {driverState.selectedContract.trip_type !== "Day Trip" &&
                      driverState.selectedContract.contract_name}
                  </p>
                )}

                <p className="text-sm ">
                  Trip Id: {driverState.selectedContract.trip_id}
                </p>
              </div>
         
            </div>
            <div className="flex justify-between w-[100%]  font-bold text-sm border-b  border-solid py-3 border-slate-100">
              <p className="text-zinc-400	">Journey start date and time</p>
              <p>
                {driverState.selectedContract.start_date} &nbsp; &nbsp;{" "}
                {tConvert(driverState.selectedContract.start_time)}
              </p>
            </div>
            {/* <div className="border-b  border-solid  border-slate-100"></div> */}
            {/* <hr /> */}
            {driverState.selectedContract.trip_type == "Contract Trip" && <div className="flex justify-between w-[100%]  font-bold text-sm border-b  border-solid py-3  border-slate-100">
              <p className="text-zinc-400	">Journey end date and time</p>
              <p>
                {driverState.selectedContract.end_date} &nbsp; &nbsp;{" "}
                {tConvert(driverState.selectedContract.end_time)}
              </p>
            </div>}
            {driverState.selectedContract.trip_type != "Contract Trip" && <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid py-3 border-slate-100">
              <p className="text-zinc-400	text-sm ">Booked for</p>
              <p className="	text-sm ">
                {driverState.selectedContract.day_trip_hour} hours
              </p>
            </div>}
            
            <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid py-3 border-slate-100">
              <p className="text-zinc-400	text-sm ">Pickup location</p>
              <p>
                {driverState.selectedContract.pickup_point?.adress},
                {driverState.selectedContract.pickup_point?.adress_type}
              </p>
            </div>

            {driverState.selectedContract.trip_type !== "Day Trip" ?  <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid py-3 border-slate-100">
              <p className="text-zinc-400	text-sm ">Dropoff Location</p>
              {driverState.selectedContract.drop_of_point?.adress},
              {driverState.selectedContract.drop_of_point?.adress_type}
            </div> : ""}
           
         


            <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid py-3 border-slate-100 ">
              <p className="text-zinc-400	text-sm ">Trip type</p>
             {driverState.selectedContract.trip_type == "Contract Trip" &&  <p> {(driverState.selectedContract.trip_type =="Contract Trip"&& driverState.selectedContract.same_as_pickup_point)? "Two way trip" : "One way trip"}</p>}
              {driverState.selectedContract.trip_type == "Day Trip" && <p>{driverState.selectedContract.trip_type == "Day Trip" && driverState.selectedContract.trip_type }</p>}
            </div>

            {/* {driverState.selectedContract.trip_type == "Contract Trip" && 
              <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid py-3 border-slate-100 ">
              <p className="text-zinc-400	text-sm ">Region</p>
             <p>{driverState.selectedContract.region?.name}</p>
            </div>
            } */}
{driverState.selectedContract.trip_type == "Day Trip" &&      <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid py-3 border-slate-100">
              <p className="text-zinc-400	text-sm ">Vehicle type</p>
              <p className=" font-extrabold 	">
                {" "}
                {driverState.selectedContract.vehicle_type}
              </p>
            </div> }
       
          </div>
         
        </div>
      </div>
      <div className="flex  justify-between">
        <div className="flex justify-between  bg-white rounded-3xl mb-8 gap-12 p-6 w-[48%]">
          <div className="flex gap-8  w-[100%]">
            <div className="flex flex-col item-center w-[100%] ">
              <div className="flex justify-between w-[100%] pb-2">
                <p className="font-bold text-lg">Customer details</p>
              </div>
              <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid py-3 border-slate-100">
                <p className="text-zinc-400	">Customer name</p>
                <p>
                  {driverState.selectedContract.user_id.first_name}{" "}
                  {driverState.selectedContract.user_id.last_name}
                </p>
              </div>
              <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid py-3 border-slate-100">
                <p className="text-zinc-400	">Mobile number</p>
                <p>{driverState.selectedContract.user_id.mobile_number}</p>
              </div>
              <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid py-3 border-slate-100">
                <p className="text-zinc-400	">Whatsapp number</p>
                <p>{driverState.selectedContract.user_id.watsap_number}</p>
              </div>
              <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid py-3 border-slate-100">
                <p className="text-zinc-400	">Email ID </p>
                <p>{driverState.selectedContract.user_id.email}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between  bg-white rounded-3xl mb-8 gap-12 p-6 w-[48%]">
          <div className="flex gap-8  w-[100%]">
            <div className="flex flex-col item-center w-[100%] gap-8">
              <div className="flex justify-between w-[100%] pb-2">
                <p className="font-bold text-lg">Price details</p>
              </div>
              <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid pb-3 border-slate-100">
                <p className="text-zinc-400	">Cost</p>
                <p> {driverState.selectedContract.price} OMR</p>
              </div>
              <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid pb-3 border-slate-100">
                <p className="text-zinc-400	">Credit points added</p>
                <p>{driverState.selectedContract.credit_point} OMR</p>
              </div>

              <div className="flex justify-between w-[100%] mt-4 text-2xl font-bold text-sm">
                <p className="text-zinc-400	">Total</p>
                <p>{driverState.selectedContract.total_amount} OMR</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverContractTripDetails;
