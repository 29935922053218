// import { configureStore } from "@reduxjs/toolkit";
// import driverSlice from "./driverSlice";
// import authSlice from "./authSlice";
// import contractRequestSlice from "./contractRequestsSlice";

// const store = configureStore({
//   reducer: {
//     driver: driverSlice.reducer,
//     auth: authSlice.reducer,
//     contractrequest: contractRequestSlice.reducer,
//   },
//   middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
// });

// export default store;

import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import driverSlice from "./driverSlice";
import authSlice from "./authSlice";
import contractRequestSlice from "./contractRequestsSlice";
import contractManagementSlice from "./contractManagementSlice";
import contractModificationSlice from "./contractModificationSlice";
import customerSlice from "./customerSlice";
import rolesSlice from "./rolesSlice";
import userSlice from "./userSlice";
import reportSlice from "./reportSlice";
import dashboardSlice from "./dashboardSlice";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import { combineReducers } from "redux";
import { getPersistConfig } from 'redux-deep-persist';



const reducers = combineReducers({
  driver: driverSlice.reducer,
  auth: authSlice.reducer,
  contractrequest: contractRequestSlice.reducer,
  contractmanagement:contractManagementSlice.reducer,
  contractmodification:contractModificationSlice.reducer,
  customer:customerSlice.reducer,
  roles:rolesSlice.reducer,
  user:userSlice.reducer,
  report:reportSlice.reducer,
  dashboard:dashboardSlice.reducer
});



// const persistConfig = {
//   key: "root",
//   version: 1,
//   storage,
//   // blacklist: ['tracking']
// };

const config = getPersistConfig({
  key: 'root',
  storage, // whatever storage you use
  blacklist: [
      'contractrequest.searchText',  
      'contractrequest.status', 
      'contractrequest.trip_Type', 
      'contractrequest.date',
      'contractrequest.allContracts',
      'contractmanagement.searchText',
      'contractmanagement.status',
      'contractmanagement.date',
      'contractmanagement.type',
      'contractmanagement.payment_link_send',
      'contractmodification.searchText',
      'contractmodification.trip_Type',
      'contractmodification.date',
      'customer.searchText',
      'customer.status',
      'driver.searchText',
      'driver.status',
      'driver.allDriverContracts',
      'driver.driverContractLoading',
      'driver.filteredDriverContracts',
      'customer.orderTrip_Type',
      'customer.orderDate',
      'customer.filteredCustomerOrders',
      'customer.allCustomerOrdersList',
      'driver.contractType',
      'driver.contractDate',
      // 'contractrequest.allContracts',
      'contractrequest.filterResults',
      'contractmanagement.allContracts',
      'contractmanagement.filteredContracts',
      'contractmodification.allContractModifications',
      'contractmodification.allFilteredContractModifications',
      'roles.status',
      'roles.searchText',
      'user.status',
      'user.searchText',
      'report.searchText',
      'report.start_date',
      'report.end_date',
      'report.status',
      'user.allUsers',
      'user.filteredUserList',
      'dashboard.allData',
      'contractmanagement.apiLoading',
      'contractrequest.apiLoading'




      // set all list inside blacklist inorder to reduce data fetching time and replace
  ],
  rootReducer:reducers, // your root reducer must be also passed here
  //... // any other props from original redux-persist config omitting the state reconciler
})

const persistedReducer = persistReducer(config, reducers);
const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const dispatchStore = configureStore({
  reducer: reducers
});

export default store;


