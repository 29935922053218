import React, { useEffect, useState } from "react";
import { Table } from "antd";
import "../DriverManagement/drivermanagement.scss";
import CardLayout from "../../common/CardLayout";
import { Select,message } from "antd";
import ModalLayout from "../../common/ModalLayout";
import DriverOnBoarding from "../../forms/DriverManagement/DriverOnBoarding";
import editicon from "../../assets/images/editicon.svg";
import deleteicon from "../../assets/images/deleteicon.svg";
import { Link, useNavigate } from "react-router-dom";
import NewUserCredentials from "../../forms/UserMangement/NewUserCredentials";
import { useDispatch,useSelector } from "react-redux";
import { getAllUsers, userSliceActions,filterUsersList,getSelectedUser, deleteUser,getAllRoleDropDown } from "../../redux/userSlice";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { getNotificationDashboard } from "../../redux/dashboardSlice";
import { clearAllContractManagement, clearAllContractMod, clearAllContractRequest, clearAllCustomer, clearAllDriver, clearAllReports, clearAllRoles } from "../../common/clearfunction";
const UserDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userState = useSelector(state=>state.user)
  const authState = useSelector(state=>state.auth)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editFlag,setEditFlag] = useState(false)
  const [editModal,setEditModal] = useState(false)
  const [editAcess,setEditAcess] = useState(false)
  const [messageApi, contextHolder] = message.useMessage();
  const [totalCount,setTotalCount] = useState(10)
  const [pageno,setPageNo] = useState(1)
  const [loader,setLoader] = useState(true)
  const showModal = () => {
    setIsModalOpen(true);
  };
  const contractManagementState = useSelector(
    (state) => state.contractmanagement
  );

  useEffect(()=>{
    // console.log(authState.acessMenus)
    for(let i =0 ; i <authState.acessMenus.length;i++){
  
      if(authState.acessMenus[i].menu == "user"){
      //  console.log(authState.acessMenus[i].menu == "contract")
      //  console.log(Object.keys(authState.acessMenus[i]?.actions))
     for(let j = 0; j < Object.keys(authState.acessMenus[i]?.actions).length ; j++){
         if(authState.acessMenus[i].actions.change){
          // console.log(true)
         setEditAcess(true)
         }
     }
    
      }
    }
  },[])


  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  useEffect(()=>{
    dispatch(getAllRoleDropDown())},[])
  const success = (content) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };
  
  const error = (content) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };

  const columns = [
    {
      title: "Name of User",
      dataIndex: "first_name",
      key: "first_name",
      render: (first_name, record) => (
        <p style={{ fontSize: "12px" }}>{first_name}</p>
      ),
    },
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
      render: (username, record) => (
        <p style={{ fontSize: "12px" }}>{username}</p>
      ),
    },
    
    {
      title: "Role",
      key: "user_group",
      dataIndex: "user_group",
      render: (user_group, record) => <p style={{ fontSize: "12px" }}>{user_group}</p>,
    },

    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (_, record) => {
        return (
          <span
            className={`flex text-sm font-bold leading-4 ${
              record.is_active  ? "text-green-700" : "text-red-700"
            }`}
          >
            {  record.is_active  ? "Active" : "Inactive"}
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <div className="flex items-center">
            <img
              src={editicon}
              alt="Edit"
              className="mx-2 cursor-pointer"
              title="Edit"
              onClick={()=>{
              if(editAcess){
                dispatch(getSelectedUser(record.id))
                setEditFlag(true)
                setEditModal(true)
              }
    // console.log(roleState.selectedRole)
  
            
              }}
            />
            <img
              src={deleteicon}
              alt="Delete"
              className="mx-2 cursor-pointer"
              title="Delete" 
              onClick={()=>{
                if(editAcess){
                  dispatch(deleteUser(record.id)).then((res)=>{
                    success("user deleted succesfully")
                  })
                
                }
              }}
            />
          </div>
        );
      },
    },
  ];
  const data = [
    {
      key: "1",
      nameofuser: "person1",
      username: "person1",
      password: "12345",
      role: "Admin",
      status: "Active",
    },
    {
      key: "2",
      nameofuser: "person1",
      username: "person1",
      password: "12345",
      role: "Admin",
      status: "Active",
    },
    {
      key: "3",
      nameofuser: "person1",
      username: "person1",
      password: "12345",
      role: "Admin",
      status: "Active",
    },
  ];


  useEffect(()=>{
    setPageNo(1)
  },[contractManagementState.setpagenumberFlag])
  const handleChange = (value) => {
    // console.log(`selected ${value}`);
  };

useEffect(()=>{ 
  dispatch(userSliceActions.setAPILoading(true))
  clearAllContractRequest()
  clearAllContractManagement()
  clearAllContractMod()
  clearAllCustomer()
  clearAllDriver()
  clearAllRoles()
  clearAllReports()
  dispatch(getNotificationDashboard())
  setLoader(true)
  // if(userState.status == ""  && userState.searchText == ""){
    if(userState.searchText == "" && userState.status == ""){
      dispatch(getAllUsers(pageno)).then(res=>setTotalCount(res.payload.count))
    }
   
    // setTimeout(()=>setLoader(false),800)
  // }
 
},[isModalOpen,userState.userCreatedFlag,userState.deleteFlag,userState.editFlag,pageno])
// console.log(userState.searchText)
useEffect(()=>{


    dispatch(userSliceActions.setAPILoading(true))
    // console.log("im working")
   const data = {
     status:userState.status,
     searchText:userState.searchText,
     page:pageno
   }
   dispatch(filterUsersList(data))
  //  setTimeout(()=>setLoader(false),800)
  
   },[userState.status,userState.searchText,pageno,userState.deleteFlag,userState.editFlag,])
const mapData = userState.status != ""  || userState.searchText != ""? userState.filteredUserList:userState.allUsers
  return (
    <CardLayout>
      {contextHolder}
      <div className="grid grid-cols-2 md:grid-cols-1 gap-6 my-5">
        <div className="col-span-1">
          <p className="font-bold text-xl leading-6 text-header-title my-2">
            User Details
          </p>
        </div>
        <div className="col-span-1 flex items-center md:justify-between justify-end flex-wrap gap-3">
          {/* <div className="my-2 flex items-center justify-between md:w-full w-1/2"> */}
          <Select
            allowClear
            className="w-[200px]  my-1 font-bold border-none bg-[#f265220d] rounded-lg"
            defaultValue="Status"
            onChange={(val)=>{
              setPageNo(1)
              dispatch(userSliceActions.setAPILoading(true))
              dispatch(userSliceActions.setStatus(val))}}
            bordered={false}
            options={[
              {
                value: "",
                label: "All",
                className:
                  "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
              },
              {
                value: true,
                label: "Active",
                className:
                  "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
              },
              {
                value: "false",
                label: "Inactive",
                className: "w-full py-[.5rem] text-xs font-bold",
              },
            ]}
          />
          <button
            type="button"
            className="w-[200px]  lg:ml-5 my-1 text-white bg-primary hover:bg-secondary  rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
            onClick={editAcess?showModal:undefined}
          >
            Add User Credentials
          </button>
          {/* </div> */}
        </div>
        <div className="flex items-center justify-between flex-wrap"></div>
      </div>
      {/* {console.log()} */}
      {userState.apiLoading? <div style={{display:"flex",justifyContent:"center"}}>
          <Spin indicator={antIcon} />
        </div>:      <Table rowKey={record => record.id}  columns={columns} dataSource={mapData} scroll={{ x: 900 }}  pagination={{  pageSize:10 ,total:totalCount ,onChange:(page,size)=>{setPageNo(page)},showSizeChanger:false }}/>
}

      <ModalLayout
        title="Add User Credentials"
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        
      >
        {/* <DriverOnBoarding
          
        /> */}
        <NewUserCredentials
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      </ModalLayout>
      <ModalLayout
        title="Edit User"
        isModalOpen={editModal}
        setIsModalOpen={setEditModal}
        editing = {editFlag}
        // selectedData={roleState.selectedRole}
      >
        <NewUserCredentials
          isModalOpen={editModal}
          setIsModalOpen={setEditModal}
          editing = {editFlag}
          name={userState.selectedUser.first_name}
          id={userState.selectedUser.id}
          username={userState.selectedUser.username}
          active={userState.selectedUser.is_active}
          role={userState.selectedUser.user_group_id}
          email={userState.selectedUser?.email}
          // selectedData={roleState.selectedRole}
        
        />
      </ModalLayout>
    </CardLayout>
  );
};

export default UserDetails;
