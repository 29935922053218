import React, { useEffect, useState } from "react";
import { Table } from "antd";
import "../DriverManagement/drivermanagement.scss";
import CardLayout from "../../common/CardLayout";
import { Select } from "antd";
import ModalLayout from "../../common/ModalLayout";
import DriverOnBoarding from "../../forms/DriverManagement/DriverOnBoarding";
import editicon from "../../assets/images/editicon.svg";
import deleteicon from "../../assets/images/deleteicon.svg";
import { Link, useNavigate } from "react-router-dom";
import NewUserCredentials from "../../forms/UserMangement/NewUserCredentials";
import UserRoleForm from "../../forms/UserMangement/UserRoleForm";
import { useDispatch,useSelector } from "react-redux";
import { getAllRoles,filterUserList,rolesActions, deleteUserRole, getSelectedRole } from "../../redux/rolesSlice";
import { message } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { clearAllUsers } from "../../common/clearfunction";

const UserRoles = () => {
  const navigate = useNavigate();
  const dispatch= useDispatch()
  const roleState = useSelector(state=>state.roles)
  const authState = useSelector(state=>state.auth)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteFalg,setDeleteFalg] = useState(false)
  const [editFlag,setEditFlag] = useState(false)
  const [editModal,setEditModal] = useState(false)
  const [editAcess,setEditAcess] = useState(false)
  const [messageApi, contextHolder] = message.useMessage();
  const [totalCount,setTotalCount] = useState(10)
  const [pageno,setPageNo] = useState(1)
  const [deleteModal,setDeleteModal] = useState(false)
  const [selectedDeleteID,setSelectedDeleteId] = useState("")
  const [loader,setLoader] = useState(true)
  const showModal = () => {
    setIsModalOpen(true);
  };

  const contractManagementState = useSelector(
    (state) => state.contractmanagement
  );

  useEffect(()=>{
    // console.log(authState.acessMenus)
    for(let i =0 ; i <authState.acessMenus.length;i++){
  
      if(authState.acessMenus[i].menu == "user"){
      //  console.log(authState.acessMenus[i].menu == "contract")
      //  console.log(Object.keys(authState.acessMenus[i]?.actions))
     for(let j = 0; j < Object.keys(authState.acessMenus[i]?.actions).length ; j++){
         if(authState.acessMenus[i].actions.change){
          // console.log(true)
         setEditAcess(true)
         }
     }
    
      }
    }
  },[])

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const success = (content) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };
  
  const error = (content) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };
  const columns = [
    {
      title: "Role",
      dataIndex: "name",
      key: "name",
      render: (name, record) => <p style={{ fontSize: "12px" }}>{name}</p>,
    },

    // {
    //   title: "Permissions",
    //   dataIndex: "rmissions",
    //   key: "missions",
    //   render: (missions, record) => (
    //     <p style={{ fontSize: "12px" }}>{missions}</p>
    //   ),
    // },

    {
      title: "Status",
      dataIndex: "role_status",
      key: "role_status",
      render: (role_status, record) => {
        return (
          <span
            className={`flex text-sm font-bold leading-4 ${
              record.role_status? "text-green-700" : "text-red-700"
            }`}
          >
            {role_status?"Active":"Inactive"}
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <div className="flex items-center">
            <img
              src={editicon}
              alt="Edit"
            onClick={()=>{
          if(editAcess){
            dispatch(rolesActions.setAPILoading(true))
            dispatch(getSelectedRole(record.id))
            console.log(roleState.selectedRole)
            setEditFlag(true)
            setEditModal(true)
          }
  // console.log(roleState.selectedRole)

          
            }}
              className="mx-2 cursor-pointer"
              title="Edit"
            />
            <img
              src={deleteicon}
              alt="Delete"
              onClick={()=>{
                if(editAcess){ setDeleteModal(true)
                  setSelectedDeleteId(record.id)}
               
              }}
              className="mx-2 cursor-pointer"
              title="Delete"
            />
          </div>
        );
      },
    },
  ];


  useEffect(()=>{
    setPageNo(1)
  },[contractManagementState.setpagenumberFlag])
  const data = [
    {
      key: "1",
      role: "Admin",
      permissions: "admin@gmail.com",
      status: "Active",
    },
    {
      key: "2",
      role: "Admin",
      permissions: "admin@gmail.com",
      status: "Active",
    },
    {
      key: "3",
      role: "Admin",
      permissions: "admin@gmail.com",
      status: "Active",
    },
  ];
  const handleChange = (value) => {
    // setSelectedStatus(value)
    // console.log(value)
  };

 
  useEffect(()=>{
    clearAllUsers()
    // setLoader(true)
    dispatch(rolesActions.setAPILoading(true))
    if(roleState.searchText == "" && roleState.status == ""){
      dispatch(getAllRoles(pageno)).then(res=>setTotalCount(res.payload.count))
      // setTimeout(()=>setLoader(false),800)
    }

  },[isModalOpen,deleteFalg,roleState.editFlag,roleState.deleteFlag,pageno,roleState.status])


  // console.log(roleState)


  useEffect(()=>{
    dispatch(rolesActions.setAPILoading(true))
//  if(roleState.searchText != "" || roleState.status != ""){
  const data = {
    status:roleState.status,
    searchText:roleState.searchText,
    page:pageno
  }
  dispatch(filterUserList(data)).then(res=>setTotalCount(res.payload.data.count))
  setTimeout(()=>setLoader(false),800)
//  }
  },[roleState.status,roleState.searchText,pageno,roleState.editFlag,roleState.deleteFlag])


const mapData = roleState.status != ""  || roleState.searchText != ""? roleState.filteredRoleList:roleState.allRoles

  return (
    <CardLayout>
          {contextHolder}
      <div className="grid grid-cols-2 md:grid-cols-1 gap-6 my-5">
        <div className="col-span-1">
          <p className="font-bold text-xl leading-6 text-header-title my-2">
            User Roles
          </p>
        </div>
        <div className="col-span-1 flex items-center md:justify-between justify-end flex-wrap gap-3">
          {/* <div className="my-2 flex items-center justify-between md:w-full w-1/2"> */}
          <Select
            allowClear
            className="w-[200px]  my-1 font-bold border-none bg-[#f265220d] rounded-lg"
            defaultValue="Status"
            onChange={(val)=>{
              setPageNo(1)
              dispatch(rolesActions.setAPILoading(true))
              dispatch(rolesActions.setStatus(val))}}
            bordered={false}
            options={[
              {
                value: "",
                label: "All",
                className:
                  "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
              },
              {
                value: "true",
                label: "Active",
                className:
                  "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
              },
              {
                value: "false",
                label: "Inactive",
                className: "w-full py-[.5rem] text-xs font-bold",
              },
            ]}
          />
          <button
            type="button"
            className="w-[200px]  lg:ml-5 my-1 text-white bg-primary hover:bg-secondary  rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
            onClick={()=>{
              if(editAcess){
                setEditFlag(false)
              showModal(true)
              }
            }}
          >
            Add User Role
          </button>
          {/* </div> */}
        </div>
        <div className="flex items-center justify-between flex-wrap"></div>
      </div>
      {roleState.apiLoading? <div style={{display:"flex",justifyContent:"center"}}>
          <Spin indicator={antIcon} />
        </div>:       <Table rowKey={record => record.id} columns={columns} dataSource={mapData} scroll={{ x: 900 }}  pagination={{  pageSize:10 ,total:totalCount ,onChange:(page,size)=>{setPageNo(page)},showSizeChanger:false }}/>}
      {/* <Table columns={columns} dataSource={mapData} scroll={{ x: 900 }}  pagination={{  pageSize:10 ,total:totalCount ,onChange:(page,size)=>{setPageNo(page)},showSizeChanger:false }}/> */}
      <ModalLayout
        title="Add User Roles"
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      >
        <UserRoleForm
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          // editing = {editFlag}
        
        />
      </ModalLayout>
      <ModalLayout
        title="Edit User Role"
        isModalOpen={editModal}
        setIsModalOpen={setEditModal}
        editing = {editFlag}
        selectedData={roleState.selectedRole}
      >
        <UserRoleForm
          isModalOpen={editModal}
          setIsModalOpen={setEditModal}
          editing = {editFlag}
          // selectedData={roleState.selectedRole}
          role = {roleState.selectedRole?.name}
          status = {roleState.selectedRole?.role_status}
          permissions = {roleState.selectedRole?.permissions}
          id={roleState.selectedRole?.id}
        />
      </ModalLayout>
   
      <ModalLayout
      isModalOpen={deleteModal}
      setIsModalOpen={setDeleteModal}
      >
      <div className="flex flex-col justify-center items-center">
      {contextHolder}
      <div className="py-10">
        <p className="text-xl font-bold">Are you sure want to delete ?</p>
      </div>
      <div className="flex justify-center ">
        <button
          onClick={() => setDeleteModal(false)}
          className="border border-black w-[150px] mr-3  lg:ml-5 my-1 text-primary   border-black rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
        >
          Cancel
        </button>
        <button
          // onClick={deleteCurrentDriver}
          onClick={()=>{
    dispatch(deleteUserRole(selectedDeleteID)).then((res)=>{
      console.log(res)
      if(res.error?.message == "Request failed with status code 400"){
        error("Role can't be deleted since users with this role exists")
        setDeleteModal(false)
      }else{
  success("deleted succesfully")
              setDeleteModal(false)
      }
    })
            
          }}
          className="border border-black w-[150px]  lg:ml-5 my-1 text-white bg-primary border-black rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
        >
          Yes
        </button>
      </div>
    </div>
      </ModalLayout>
    </CardLayout>
  );
};

export default UserRoles;
