import React, { useState, useEffect, useCallback } from "react";
import { Table } from "antd";
import "./drivermanagement.scss";
import CardLayout from "../../common/CardLayout";
import { Select } from "antd";
import ModalLayout from "../../common/ModalLayout";
import DriverOnBoarding from "../../forms/DriverManagement/DriverOnBoarding";
import viewicon from "../../assets/images/viewicon.svg";
import editicon from "../../assets/images/editicon.svg";
import deleteicon from "../../assets/images/deleteicon.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd'; 
import {
  getAllDrivers,
  driverActions,
  updateDriver,
  getSingleDriver,
  getAllFilteredDriver
} from "../../redux/driverSlice";
import DeleteDriver from "./DeleteDriver";
import { getNotificationDashboard } from "../../redux/dashboardSlice";
// import { getSingleDriver } from "../../redux/driverSlice";
import fileDownload from "js-file-download";
import axios from "axios";
import { clearAllContractManagement, clearAllContractMod, clearAllContractRequest, clearAllCustomer, clearAllReports, clearAllRoles, clearAllUsers } from "../../common/clearfunction";

const DriverList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const driverState = useSelector((state) => state.driver);
  const authState = useSelector(state=>state.auth)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentUserId, setCurrentUserId] = useState("");
  const [allDrivers, setAllDrivers] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editAcess,setEditAcess] = useState(false)
  const [totalCount,setTotalCount] = useState(10)
  const [pageno,setPageNo] = useState(1)
  // const [loader,setLoader] = useState(true)
  const [deleteRefresh,setDeleteRefresh] = useState(false)
  const contractManagementState = useSelector(
    (state) => state.contractmanagement
  );
  // const [status, setStatus] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(()=>{
    dispatch(driverActions.clearDriverContractFilters())
    // console.log(authState.acessMenus)
    for(let i =0 ; i <authState.acessMenus.length;i++){
  
      if(authState.acessMenus[i].menu == "driver"){
      //  console.log(authState.acessMenus[i].menu == "contract")
      //  console.log(Object.keys(authState.acessMenus[i]?.actions))
     for(let j = 0; j < Object.keys(authState.acessMenus[i]?.actions).length ; j++){
         if(authState.acessMenus[i].actions.change){
          // console.log(true)
         setEditAcess(true)
         }
     }
    
      }
    }
  },[])
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  useEffect(() => {
    dispatch(driverActions.setAPILoading(true))
    clearAllContractRequest()
    clearAllContractManagement()
    clearAllContractMod()
    clearAllCustomer()
    clearAllUsers()
    clearAllRoles()
    clearAllReports()
    dispatch(getNotificationDashboard())
    // setLoader(true)
    // if(driverState.searchText == "" && driverState.status == ""){
      dispatch(getAllDrivers(pageno)).then(res=>{
        // console.log(res)
      if(driverState.searchText == "" && driverState.status == ""){
 setTotalCount(res.payload.count)
      }
    });
      setAllDrivers(driverState.allDrivers);
      // setTimeout(()=>setLoader(false),800)
    // }
   
  }, [allDrivers, isModalOpen, isEdit, pageno,deleteRefresh]);

  const singleDriverNav = (record) => {
    dispatch(driverActions.setDriverId(record.id))
    dispatch(driverActions.setDriver_ID(record.driver_id))

    navigate(`driver-details/${record.id}`);
  };

  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  useEffect(()=>{
    setPageNo(1)
  },[contractManagementState.setpagenumberFlag])

  const deleteSingleDriver = (record) => {
    // dispatch(deleteDriver(record.id));
    setCurrentUserId(record.id);
    setIsDeleteModalOpen(true);
  };

  const columns = [
    {
      title: "Driver ID",
      dataIndex: "driver_id",
      key: "driver_id",
      render: (driver_id, record) => (
        <p style={{ fontSize: "12px" }}>{driver_id}</p>
      ),
    },
    {
      title: "Driver name",
      dataIndex: "drivername",
      key: "drivername",
      render: (drivername, record) => (
        <p style={{ fontSize: "12px" }}>{drivername}</p>
      ),
    },
    {
      title: "Contact number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      render: (mobile_number, record) => (
        <p style={{ fontSize: "12px" }}>{mobile_number}</p>
      ),
    },
    {
      title: "Whatsapp number",
      key: "whatsapp_number",
      dataIndex: "whatsapp_number",
      render: (whatsapp_number, record) => (
        <p style={{ fontSize: "12px" }}>{whatsapp_number}</p>
      ),
    },
    {
      title: "Email ID",
      dataIndex: "email_address",
      key: "email_address",
      render: (email_address, record) => (
        <p style={{ fontSize: "12px" }}>{email_address?email_address:"N/A"}</p>
      ),
    },
    // {
    //   title: "Vehicle registration number",
    //   dataIndex: "registraion_number",
    //   key: "registraion_number",
    //   render: (registraion_number, record) => (
    //     <p style={{ fontSize: "12px" }}>{registraion_number}</p>
    //   ),
    // },
    {
      title: "Type Of vehicle",
      dataIndex: "vehicle_type",
      key: "vehicle_type",
      render: (vehicle_type, record) => (
        <p style={{ fontSize: "12px" }}>{vehicle_type?vehicle_type:"N/A"}</p>
      ),
    },
    {
      title: "Driver's license",
      dataIndex: "driver_license",
      key: "driver_license",
      render: (_, record) => {
        return {
          props: {
            style: { color: record.driver_license?"#f26522":"black" },
          },

          children: (
     
            record.driver_license ?      <a
        href={record.driver_license}
          download
        >
          {" "}
          License
        </a>:"N/A"
          ),
        };
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        return (
          <span
            className={`flex text-sm font-bold leading-4 ${
              record.status === "Active" ? "text-green-700" : "text-red-700"
            }`}
          >
            {record.status}
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, records) => {
        return (
          <div className="flex items-center">
            <img
              src={viewicon}
              alt="View"
              className="mx-2 cursor-pointer"
              title="View"
              onClick={() => {
                singleDriverNav(records);
              }}
            />

            <img
              src={editicon}
              alt="Edit"
              className="mx-2 cursor-pointer"
              onClick={() => {
               if(editAcess){
                dispatch(getSingleDriver(records.id));

                setIsEdit(true);
               }
              }}
              title="Edit"
            />
            <img
              src={deleteicon}
              alt="Delete"
              onClick={() => {
                if(editAcess){
                  deleteSingleDriver(records);
                }
              }}
              className="mx-2 cursor-pointer"
              title="Delete"
            />
          </div>
        );
      },
    },
  ];
  const data = [
    {
      key: "1",
      driverid: "12345",
      drivername: "John Brown",
      contactnumber: "(303) 555-0105",
      whtasappnumber: "(303) 555-0105",
      emailid: "j@gmail.com",
      vehicleregistartionnumber: "0105",
      typeofvehicle: "classic",
      driverslicense: "driverslicense",
      status: "Active",
    },
    {
      key: "2",
      driverid: "12345",
      drivername: "John Brown",
      contactnumber: "(303) 555-0105",
      whtasappnumber: "(303) 555-0105",
      emailid: "j@gmail.com",
      vehicleregistartionnumber: "0105",
      typeofvehicle: "classic",
      driverslicense: "driverslicense",
      status: "Active",
    },
    {
      key: "3",
      driverid: "12345",
      drivername: "John Brown",
      contactnumber: "(303) 555-0105",
      whtasappnumber: "(303) 555-0105",
      emailid: "j@gmail.com",
      vehicleregistartionnumber: "0105",
      typeofvehicle: "classic",
      driverslicense: "driverslicense",
      status: "Active",
    },
  ];
  // const handleChange = (value) => {
  //   dispatch(driverStatus(value));
  //   // setStatus(true);
  //   dispatch(driverActions.setStatusFlag(true));
  // };

  useEffect(()=>{
    dispatch(driverActions.setAPILoading(true))
 const data= {
    searchText : driverState.searchText,
    status: driverState.status,
    page:pageno
  }
  dispatch(getAllFilteredDriver(data)).then(res=>{
    // console.log(res.payload.data.count)
    setTotalCount(res.payload.data.count)})
  // setTimeout(()=>setLoader(false),800)
},[driverState.searchText,driverState.status,pageno,driverState.editDriverStatus,driverState.deleteDriverStatus])
const mapdata = driverState.searchText != "" || driverState.status != "" ? driverState.filteredDrivers : driverState.allDrivers

  return (
    <CardLayout>
      <div className="grid grid-cols-2 md:grid-cols-1 gap-6 my-5">
        <div className="col-span-1">
          <p className="font-bold text-xl leading-6 text-header-title my-2">
            Driver details
          </p>
        </div>
        <div className="col-span-1 flex items-center md:justify-between justify-end flex-wrap gap-3 select1">
          {/* <div className="my-2 flex items-center justify-between md:w-full w-1/2"> */}
          <Select
            // allowClear
            className="w-[200px]  my-1 font-bold border-none select1 bg-[#f265220d] rounded-lg  "
            defaultValue="status"
            onChange={(val)=>{
              setPageNo(1)
              dispatch(driverActions.setAPILoading(true))
              dispatch(driverActions.setStatus(val))}}
            // align={{ offset: [-90, 9] }}
            bordered={false}
            options={[
              {
                value: "",
                label: "All",
                className:
                  "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
              },
              {
                value: "Active",
                label: "Active",
                className:
                  "w-full py-[.5rem] text-xs   border-b border-orange-300 	 rounded-none pb-3 font-bold",
              },
              {
                value: "Inactive",
                label: "Inactive",
                className: "w-full py-[.5rem] text-xs font-bold ",
              },
            ]}
          />
          <button
            type="button"
            disabled={!editAcess}
            className="w-[200px]  lg:ml-5 my-1 text-white bg-primary hover:bg-secondary  rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
            onClick={showModal}
          >
            Driver on boarding
          </button>
          {/* </div> */}
        </div>
        <div className="flex items-center justify-between flex-wrap"></div>
      </div>
      {driverState.apiLoading? <div style={{display:"flex",justifyContent:"center"}}>
          <Spin indicator={antIcon} />
        </div>: <Table
         rowKey={record => record.id}
        columns={columns}
        // dataSource={allDrivers} 
        dataSource={
          

     
          mapdata

      
        }
        pagination={{total:totalCount,onChange:(page,size)=>{{
            // console.log(page,size)
            setPageNo(page)}}, current:pageno}}
        // pagination={{  ,total:totalCount ,onChange:(page,size)=>{{
        //   console.log(page,size)
        //   setPageNo(page)}},showSizeChanger:false }}
        // .length > 1 ? allDrivers :
       
        scroll={{ x: 900 }} 
      /> }
     
      <ModalLayout
        title="Driver on boarding"
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      >
        <DriverOnBoarding
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      </ModalLayout>
      <ModalLayout
        title="Driver on boarding"
        isModalOpen={isEdit}
        setIsModalOpen={setIsEdit}
        className="driveronboarding-model"
      >
        <DriverOnBoarding
          editing={isEdit}
          isModalOpen={isEdit}
          setIsModalOpen={setIsEdit}
          drivername={driverState.currentDriver.drivername}
          mobile_number={driverState.currentDriver.mobile_number}
          whatsapp_number={driverState.currentDriver.whatsapp_number}
          email_address={driverState.currentDriver.email_address}
          // driver_photo={driverState.currentDriver.driver_photo}
          // registraion_number={driverState.currentDriver.registraion_number}
          vehicle_type={driverState.currentDriver.vehicle_type}
          // driver_license={driverState.currentDriver.driver_license}
          // vehicle_license={driverState.currentDriver.vehicle_license}
          driver_type={driverState.currentDriver.driver_type}
          status={driverState.currentDriver.status}
          region={driverState.currentDriver.region}
          card_holder_name={driverState.currentDriver.card_holder_name}
          bank_account_number={driverState.currentDriver.bank_account_number}
        />
      </ModalLayout>
      <ModalLayout
        // title="Driver on boarding"
        isModalOpen={isDeleteModalOpen}
        setIsModalOpen={setIsDeleteModalOpen}
        width={500}
        setDeleteRefresh={setDeleteRefresh}
        deleteRefresh={deleteRefresh}
      >
        <DeleteDriver
          id={currentUserId}
          setIsModalOpen={setIsDeleteModalOpen}
          isModalOpen={isDeleteModalOpen}
          setDeleteRefresh={setDeleteRefresh}
          deleteRefresh={deleteRefresh}
        />
      </ModalLayout>
   
    </CardLayout>
  );
};

export default DriverList;
