import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const AdminDashboard = () => {
  const authState = useSelector((state) => state.auth);
  const navigation = useNavigate();
  // useEffect(() => {
  //   if (authState.loggedIn) {
  //     navigation("/admin/dashboard");
  //   }
  // }, [authState.loggedIn]);
  const [open, setOpen] = useState(true);
  return (
    <div className="flex  w-[100%]  justify-stretch items-stretch	 ">
      <Sidebar open={open} setOpen={setOpen} />
      <div
        //   className={` ${
        //     open ? "pl-[350px]" : "pl-[5rem]"
        //   } w-full  bg-bgcolor min-h-screen  `}

        className={`${open ? "w-[80%]" : "w-[93%]"} bg-bgcolor min-h-screen 	`}
      >
        {/* <div > */}
        <Outlet className="" />
        {/* </div> */}
      </div>
    </div> 
  );
};

export default AdminDashboard;
