import React from "react";
import "./toggle.css";
import { useState, useEffect } from "react";
const ToggleSwitch = ({ userRoleSelection }) => {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    userRoleSelection(checked);
  });

  return (
    <div>
      <label className="switch">
        <input type="checkbox" onChange={(e) => setChecked(e.target.checked)} />

        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default ToggleSwitch;
