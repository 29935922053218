import React,{useEffect, useState} from "react";
// import { Link } from "react-router-dom";
import { Link } from "react-router-dom";
// import PageHeader from "../../layout/PageHeader";
import CustomerPageHeader from "../CustomerManagement/CustomerPageHeader";
import { useSelector } from "react-redux";
import PageHeaderContractReqTripDetails from "../ContractRequests/PageHeaderContractReqTripDetails";
import { useNavigate } from "react-router-dom";
import ModalLayout from "../../common/ModalLayout";
import contractImg from '../../assets/images/contractprop.png'
import { tConvert } from "../../common/timeConverter";
import { contractManagementActions } from "../../redux/contractManagementSlice";
import { useDispatch } from "react-redux";
const ContractTripDetails = () => {
  const contractManagementState = useSelector(state=> state.contractmanagement)
  const navigate = useNavigate()
  const [showContract,setShowContract] = useState(false)
  const [showContractDayTrip,setShowContractDayTrip] = useState(false)
  const [showContractContractTrip,setshowContractContractTrip] = useState(false)
  const dispatch = useDispatch()

useEffect(()=>{
  dispatch(contractManagementActions.clearAllFilters())
},[])

  const SubTitle = () => {
    return (
      <div className="flex items-center my-2">
        <Link
          to="/admin/contract-management"
          className="leading-5 text-base font-medium text-breadcrumb hover:text-black black-text"
        >
          Contract management
        </Link>

        <div className="inline-flex items-center justify-center w-2 h-2 mx-3 bg-breadcrumb border-breadcrumb rounded-full "></div>

        <span className="leading-5 text-base font-medium text-header-title">
          Trip details
        </span>
      </div>
    );
  };
  return (
    <div className="m-10">
      <PageHeaderContractReqTripDetails pageTtitle="Trip Details" SubTitle={SubTitle} />
      <div className="flex justify-between  bg-white rounded-3xl mb-8 gap-12 p-6 ">
        <div className="flex gap-8  w-[100%]">
          <div className="flex flex-col item-center w-[100%] gap-8">
            <div className="flex justify-between w-[100%] pb-4">
              <div>
                <p className="text-xl font-extrabold text-orange-500	">
                {contractManagementState.selectedContract.trip_type}
                </p>
                {contractManagementState.selectedContract.trip_type ==
                  "Contract Trip" && (
                  <p className="text-sm ">
                    Contract name :{" "}
                    {contractManagementState.selectedContract.trip_type !== "Day Trip" &&
                      contractManagementState.selectedContract.contract_name}
                  </p>
                )}
                <p className="text-sm ">Trip Id:{contractManagementState.selectedContract.trip_id}</p>
              </div>
              <div>
                <p className="px-14 py-2 text-md bg-orange-100 rounded-lg font-bold text-orange-500">
                  {contractManagementState.selectedContract.trip_payment?contractManagementState.selectedContract.trip_payment?.status:"Not paid"}
                </p>
                {/* {console.log(contractManagementState.selectedContract.trip_payment)} */}
              </div>
            </div>
          {contractManagementState.selectedContract.trip_type == "Contract Trip"
? <div className="flex justify-between w-[100%]  font-bold text-sm">
<p className="text-zinc-400	">Contract start date and time</p>
<p>      {contractManagementState.selectedContract.start_date} &nbsp; &nbsp;{" "}
  {tConvert(contractManagementState.selectedContract.start_time)}</p>
</div>
:  <div className="flex justify-between w-[100%]  font-bold text-sm">
              <p className="text-zinc-400	">Starting date and time</p>
              <p>      {contractManagementState.selectedContract.start_date} &nbsp; &nbsp;{" "}
                {tConvert(contractManagementState.selectedContract.start_time)}</p>
            </div>}

            {contractManagementState.selectedContract.trip_type == "Contract Trip"&&<div className="flex justify-between w-[100%]  font-bold text-sm">
<p className="text-zinc-400	">Contract end date and time</p>
<p>      {contractManagementState.selectedContract.end_date} &nbsp; &nbsp;{" "}
  {tConvert(contractManagementState.selectedContract.end_time)}</p>
</div>}
           {contractManagementState.selectedContract.trip_type != "Contract Trip"&& <div className="flex justify-between w-[100%] font-bold text-sm">
              <p className="text-zinc-400	">Booked for</p>
              <p>{contractManagementState.selectedContract.day_trip_hour} hours</p>
            </div>}
            <div className="flex justify-between w-[100%] font-bold text-sm">
              <p className="text-zinc-400	">Pickup location</p>
              <p>{contractManagementState.selectedContract.pickup_point?.adress}</p>
            </div>
{/* 

            {
            contractManagementState.selectedContract.adresses?.length > 0 &&  <div className="flex justify-between w-[100%] font-bold text-sm border-b  border-solid py-3 border-slate-100">
            <p className="text-zinc-400	text-sm ">Stops</p>
            {console.log(contractManagementState.selectedContract.adresses)}
            <div>
              {contractManagementState.selectedContract.adresses.map((ad=>(
                
            <p className="	text-sm ">
          <span className="font-normal"> Stop {ad.stop_number} &nbsp; : &nbsp; </span>
           {ad.adress_id.adress} 
             </p>
              )))}
            </div>
           
          </div> 
           } */}

{contractManagementState.selectedContract.trip_type == "Day Trip" ? null :  <div className="flex justify-between w-[100%] font-bold text-sm">
              <p className="text-zinc-400	">Dropoff location</p>
              <p> {contractManagementState.selectedContract.drop_of_point?.adress}</p>
            </div>}
          
            <div className="flex justify-between w-[100%] font-bold text-sm ">
              <p className="text-zinc-400	text-sm ">Trip type</p>
             {contractManagementState.selectedContract.trip_type == "Contract Trip" &&  <p> {(contractManagementState.selectedContract.trip_type =="Contract Trip"&& contractManagementState.selectedContract.same_as_pickup_point)? "Two way trip" : "One way trip"}</p>}
              {contractManagementState.selectedContract.trip_type == "Day Trip" && <p>{contractManagementState.selectedContract.trip_type == "Day Trip" && contractManagementState.selectedContract.trip_type }</p>}
            </div>
       {/* {contractManagementState.selectedContract. trip_type == "Contract Trip" &&      <div className="flex justify-between w-[100%] font-bold text-sm">
              <p className="text-zinc-400	">Region</p>
              <p>{contractManagementState.selectedContract.region?.name}</p>
            </div>} */}
            <div className="flex justify-between w-[100%] font-bold text-sm">
              <p className="text-zinc-400	">Driver</p>
              <p
               onClick={()=>{
                contractManagementState.currentContractModal.trip_driver != null && navigate(`driverDetails`)
              }} className={` ${contractManagementState.currentContractModal.trip_driver != null? "text-orange-500	cursor-pointer" : "text-red-300"} font-extrabold `}>{contractManagementState.currentContractModal.trip_driver != null? "Driver Details" : "Driver not assigned"}</p>
            </div>
            <div className="flex justify-between w-[100%] font-bold text-sm">
              <p className="text-zinc-400	">Contract copy</p>
              <p onClick={()=>{
                   if(contractManagementState.selectedContract.trip_type == "Day Trip"){
                    setShowContractDayTrip(true)
                   }else{
                    setshowContractContractTrip(true)
                   }
              }} className=" font-extrabold text-orange-500	cursor-pointer">
                Contract proposition
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <ModalLayout
 width={600}
 isModalOpen={showContract}
 setIsModalOpen={setShowContract}
 className="flex justify-center items-center"
 style={{marginBottom:"200px"}}
>
<div className="flex justify-between   bg-white rounded-3xl  gap-4 p-4 flex-col">
<div className="flex justify-between w-[100%] pb-4 border-b  border-solid pb-4 border-slate-100">
              <div className="">
                <p className="text-2xl font-extrabold text-black	">
              Contract proposition
                </p>
             
               
              </div>
             
            </div>
        <img src={contractImg} alt="" />
</div>




</ModalLayout> */}




<ModalLayout
 width={600}
 isModalOpen={showContractDayTrip}
 setIsModalOpen={setShowContractDayTrip}
 className="flex justify-center items-center"
 style={{marginBottom:"100px"}}
>
<div className="flex justify-between   bg-white rounded-3xl  gap-4 p-4 flex-col">
<div className="flex justify-between w-[100%] pb-4 border-b  border-solid pb-4 border-slate-100">
              <div className="">
                <p className="text-2xl font-extrabold text-black	">
              Contract proposition
                </p>
             
               
              </div>
             
            </div>
      <div>
        <p className="text-red-500 text-lg font-bold">For Day trip</p>
        <p className="text-md font-bold">Cars and driver’s documentations: <br /></p>
        <p>
- The car model is not less than 7 years old from the current year. <br />
- Motor vehicle license card must be registered with the name of the <br />
driver <br />
- Vehicle driving license must be 3 years or more.<br />
- Drivers must have a vehicle operating card from the Sanad office or <br />
from the Ministry of Transport, Communications, and Information <br />
Technology.<br />
- The vehicle must be clean, in a decent condition, and has Otaxi <br />
stickers. The vehicle will be subject to inspection by the Otaxi team.<br />
- All the official documentation should be valid during the trip</p>
<p className="text-md font-bold">Driver’s behaviors:<br /></p>
<p>
- No phones are allowed while driving.<br />
- Smoking is not allowed during the trip with customers.<br />
- It’s not allowed for the driver to ask the customers private questions.<br />
- Driver must abide by the agreed time of going and return, and to be <br />
present ten minutes before the actual time of the trip.<br />
- It’s not allowed for the driver to change with another driver without <br />
getting approval from the administration of Otaxi.<br />
- In the event of any violations, the case will be referred to the legal <br />
affairs.</p>
<p className="text-md font-bold">Financial terms:<br /> </p>
<p>
  <p className="font-medium">Customer:<br /></p>

- Customers must make the full payment before the start of the trip.<br />
- There is a refund in the case of cancellations.<br />
<p className="font-medium">Driver:<br /></p>

- It’s not allowed to ask the customer for the payment.<br />
- Financial department will transfer the trip’s cost when the trip is <br />
finished.<br />
- Driver should have an account at Bank Muscat.<br />
- The Monthly Contract department is not responsible for the incorrect <br />
bank information.</p>
      </div>
      {/* <div className="flex justify-center">
      <button
                  //  type="submit"
                  // type="submit"
                  onClick={()=>{
                    dispatch(proceedTrip(contractManagementState.currentContractModal.id)).then(()=>{
                      
                      setShowContractDayTrip(false)})
                      setIsModalSendContractOpen(true)
                      success("Contract proposition approved")
                  }}
                  className="w-[200px]  lg:ml-5 my-1 text-white bg-primary hover:text-white hover:border-primary border-2 border-black rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
                >
                  Send contract proposition
                </button>
      </div> */}
</div>
</ModalLayout>



<ModalLayout
 width={600}
 isModalOpen={showContractContractTrip}
 setIsModalOpen={setshowContractContractTrip}
 className="flex justify-center items-center mb-10"
 style={{marginBottom:"100px"}}

>
<div className="flex justify-between   bg-white rounded-3xl  gap-4 p-4 flex-col">
<div className="flex justify-between w-[100%] pb-4 border-b  border-solid pb-4 border-slate-100">
              <div className="">
                <p className="text-2xl font-extrabold text-black	">
              Contract proposition
                </p>
             
               
              </div>
             
            </div>
      <div>
        <p className="text-red-500 text-lg font-bold">For Contract trip</p>
        <p className="text-md font-bold">Cars and driver’s documentations: <br /></p>
        <p>
- The car model is not less than 7 years old from the current year. <br />
- Motor vehicle license card must be registered with the name of the <br />
driver <br />
- Vehicle driving license must be 3 years or more.<br />
- Drivers must have a vehicle operating card from the Sanad office or <br />
from the Ministry of Transport, Communications, and Information <br />
Technology.<br />
- The vehicle must be clean, in a decent condition, and has Otaxi <br />
stickers. The vehicle will be subject to inspection by the Otaxi team.<br />
- All the official documentation should be valid during the trip</p>
<p className="text-md font-bold">Driver’s behaviors:<br /></p>
<p>
- No phones are allowed while driving.<br />
- Smoking is not allowed during the trip with customers.<br />
- It’s not allowed for the driver to ask the customers private questions.<br />
- Driver must abide by the agreed time of going and return, and to be <br />
present ten minutes before the actual time of the trip.<br />
- It’s not allowed for the driver to change with another driver without <br />
getting approval from the administration of Otaxi.<br />
- In the event of any violations, the case will be referred to the legal <br />
affairs.</p>
<p className="text-md font-bold">Financial terms:<br /> </p>
<p>
  <p className="font-medium">Customer:<br /></p>

  - Customers must make the full payment before the first date of <br />
contract. <br />
- There is no refund in the case of cancellations if the customer <br />
consumed half the duration of the contract. <br />
- Weekends and official holidays are not included in the contract except <br />
at the request of the customer. <br />
-It’s not allowed to delay/ postpone days from the contract period to <br />
after that period. <br />
<p className="font-medium">Driver:<br /></p>

- It’s not allowed to ask the customer for the payment. <br />
- Financial department will transfer the contract's cost after the contract <br />
is over. <br />
- Driver should have an account at Bank Muscat. <br />
- The Monthly Contract department is not responsible for the incorrect <br />
bank information. <br />
</p>
      </div>
      {/* <div className="flex justify-center">
      <button
                  //  type="submit"
                  // type="submit"
                  onClick={()=>{
                    dispatch(proceedTrip(contractManagementState.currentContractModal.id)).then(()=>{
                      setIsModalSendContractOpen(true)
                      setshowContractContractTrip(false)})
                      success("Contract proposition approved")
                  }}
                  className="w-[200px]  lg:ml-5 my-1 text-white bg-primary hover:text-white hover:border-primary border-2 border-black rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
                >
                  Send contract proposition
                </button>
      </div> */}
</div>
</ModalLayout>




      <div className="flex  justify-between">
        <div className="flex justify-between  bg-white rounded-3xl mb-8 gap-12 p-6 w-[48%]">
          <div className="flex gap-8  w-[100%]">
            <div className="flex flex-col item-center w-[100%] gap-8">
              <div className="flex justify-between w-[100%] pb-2">
                <p className="font-bold text-lg">Customer details</p>
              </div>
              <div className="flex justify-between w-[100%] font-bold text-sm ">
                <p className="text-zinc-400	">Customer name</p>
                <p>{`${contractManagementState.selectedContract.user_id.first_name} ${contractManagementState.selectedContract.user_id.last_name}`}</p>
              </div>
              <div className="flex justify-between w-[100%] font-bold text-sm">
                <p className="text-zinc-400	">Mobile number</p>
                <p>{contractManagementState.selectedContract.user_id.mobile_number}</p>
              </div>
              <div className="flex justify-between w-[100%] font-bold text-sm">
                <p className="text-zinc-400	">Whatsapp number</p>
                <p>{contractManagementState.selectedContract.user_id.watsap_number}</p>
              </div>
              <div className="flex justify-between w-[100%] font-bold text-sm">
                <p className="text-zinc-400	">Email ID </p>
                <p>{contractManagementState.selectedContract.user_id.email}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between  bg-white rounded-3xl mb-8 gap-12 p-6 w-[48%]">
          <div className="flex gap-8  w-[100%]">
            <div className="flex flex-col item-center w-[100%] gap-8">
              <div className="flex justify-between w-[100%] pb-2">
                <p className="font-bold text-lg">Price and payment details</p>
              </div>
              <div className="flex justify-between w-[100%] font-bold text-sm">
                <p className="text-zinc-400	">Cost</p>
                <p>{contractManagementState.selectedContract.price} OMR</p>
              </div>
              <div className="flex justify-between w-[100%] font-bold text-sm">
                <p className="text-zinc-400	">Credit points added</p>
                <p>{contractManagementState.selectedContract.credit_point} OMR</p>
              </div>
              <div className="flex justify-between w-[100%] font-bold text-sm">
                <p className="text-zinc-400	">Total amount</p>
                <p>{contractManagementState.selectedContract.total_amount} OMR</p>
              </div>
              <div className="flex justify-between w-[100%] font-bold text-sm">
                <p className="text-zinc-400	">Payment mode </p>
                <p> {contractManagementState.selectedContract.trip_payment?contractManagementState.selectedContract.trip_payment.payment_mode:"Not paid"}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractTripDetails;
