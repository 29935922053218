import React from "react";
import PageHeader from "../layout/PageHeader";
import { useState } from "react";
import ToggleSwitch from "../components/UserManagement/ToggleSwitch";
import UserDetails from "../components/UserManagement/UserDetails";
import UserRoles from "../components/UserManagement/UserRoles";
import UserPageHeader from "../components/UserManagement/UserPageHeader";
const UserManagement = () => {
  const [userRoles, setUserRoles] = useState(true);
  const userRoleSelection = (checked) => {
    setUserRoles(checked);
  };
  return (
    <div className="m-10">
      <UserPageHeader pageTtitle="User Management" toggle={userRoles}/>
      <div className="toggleContainer">
        <p style={{ fontSize: "19px", fontWeight: 500, marginRight: "10px" }}>
          {userRoles ? "UserRoles" : "User Credentials"}
        </p>
        <ToggleSwitch userRoleSelection={userRoleSelection} />
      </div>
      {userRoles ? <UserRoles /> : <UserDetails />}
    </div>
  );
};

export default UserManagement;
