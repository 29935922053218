import React from "react";
// import { Link } from "react-router-dom";
import Link from "antd/es/typography/Link";
// import PageHeader from "../../layout/PageHeader";
import CustomerPageHeader from "./CustomerPageHeader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { tConvert } from "../../common/timeConverter";

const TripDetails = () => {
  const customerState = useSelector((state)=>state.customer)
  const navigate = useNavigate()
  const SubTitle = () => {
    return (
      <div className="flex items-center my-2">
        <Link
          to="/customer-management"
          className="leading-5 text-base font-medium text-breadcrumb hover:text-black black-text"
        >
          Customer management
        </Link>
        <div className="inline-flex items-center justify-center w-2 h-2 mx-3 bg-breadcrumb border-breadcrumb rounded-full "></div>
        <span className="leading-5 text-base font-medium text-header-title">
          Customer details
        </span>
        <div className="inline-flex items-center justify-center w-2 h-2 mx-3 bg-breadcrumb border-breadcrumb rounded-full "></div>

        <span className="leading-5 text-base font-medium text-header-title">
          Trip details
        </span>
      </div> 
    );
  };
  // console.log(customerState.selectedCustomerOrder)
  const {trip_id,status,start_date,start_time,no_of_days,pickup_point,drop_of_point,trip_type,trip_driver,end_date,end_time,total_amount,credit_point,price,day_trip_hour,} = customerState.selectedCustomerOrder
  // console.log(trip_id)
  return (
    <div className="m-10">
      <CustomerPageHeader pageTtitle="Trip Details" SubTitle={SubTitle} />
      <div className="flex justify-between  bg-white rounded-3xl mb-8 gap-12 p-6 ">
        <div className="flex gap-8  w-[100%]">
          <div className="flex flex-col item-center w-[100%] gap-8">
            <div className="flex justify-between w-[100%] pb-4">
              <div>
                <p className="text-xl font-extrabold text-orange-500	">
                 {trip_type}
                </p>
                <p className="text-md ">Trip Id: {trip_id}</p>
              </div>
              <div>
                <p className="px-16 py-2 text-md bg-orange-100 rounded-lg font-bold text-orange-500">
                  {status}
                </p>
              </div>
            </div>
            <div className="flex justify-between w-[100%]  font-bold">
              <p className="text-zinc-400	">Starting date and time</p>
              <p>{start_date} &nbsp; &nbsp; {tConvert(start_time)}</p>
            </div>
            {trip_type == "Contract Trip" && <div className="flex justify-between w-[100%]  font-bold">
              <p className="text-zinc-400	">End date and time</p>
              <p>{end_date} &nbsp; &nbsp; {tConvert(end_time)}</p>
            </div>}
            {trip_type != "Contract Trip" && <div className="flex justify-between w-[100%] font-bold">
              <p className="text-zinc-400	">Booked for</p>
              <p>{day_trip_hour + " hours"}</p>
            </div>}
            <div className="flex justify-between w-[100%] font-bold">
              <p className="text-zinc-400	">Pickup location</p>
              <p>{pickup_point.adress}</p>
            </div>
          {trip_type == "Contract Trip" ?  <div className="flex justify-between w-[100%] font-bold">
              <p className="text-zinc-400	">Dropoff Location</p>
              <p>{drop_of_point.adress}</p>
            </div>:"" }
            {/* <div className="flex justify-between w-[100%] font-bold">
              <p className="text-zinc-400	">Trip type</p>
              <p>{trip_type}</p>
            </div> */}


            <div className="flex justify-between w-[100%] font-bold t ">
              <p className="text-zinc-400	text-sm ">Trip type</p>
             {trip_type == "Contract Trip" &&  <p> {(trip_type =="Contract Trip"&& customerState.selectedCustomerOrder.same_as_pickup_point)? "Two way trip" : "One way trip"}</p>}
              {trip_type == "Day Trip" && <p>{trip_type == "Day Trip" && trip_type }</p>}
            </div>

            {/* {trip_type == "Contract Trip" && 
              <div className="flex justify-between w-[100%] font-bold t ">
              <p className="text-zinc-400	text-sm ">Region</p>
         <p>{customerState.selectedCustomerOrder.region?.name}</p>
            </div>} */}

            <div className="flex justify-between w-[100%] font-bold">
              <p className="text-zinc-400	">Driver and Vehicle details</p>
              <p onClick={()=>{
               customerState.selectedCustomerOrder.trip_driver != null && navigate("driverDetails") 
              }} className={` font-extrabold ${customerState.selectedCustomerOrder.trip_driver != null ? "text-orange-500	cursor-pointer" : "text-red-300"} `}>{customerState.selectedCustomerOrder.trip_driver != null ? "Driver details" : "Driver details not available"}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between  bg-white rounded-3xl mb-8 gap-12 p-6 ">
        <div className="flex gap-8  w-[100%]">
          <div className="flex flex-col item-center w-[100%] gap-8">
            <div className="flex justify-between w-[100%] pb-2">
              <p className="font-bold text-xl">Price and Payment details</p>
            </div>
            <div className="flex justify-between w-[100%] font-bold">
              <p className="text-zinc-400	">Cost</p>
              <p>{price} OMR</p>
            </div>
            <div className="flex justify-between w-[100%] font-bold">
              <p className="text-zinc-400	">Credit points added</p>
              <p>{credit_point} OMR</p>
            </div>
            <div className="flex justify-between w-[100%] font-bold">
              <p className="text-zinc-400	">Total Amount</p>
              <p>{total_amount} OMR</p>
            </div>
            <div className="flex justify-between w-[100%] font-bold">
              <p className="text-zinc-400	">Payment mode </p>
              <p>{customerState.selectedCustomerOrder?.trip_payment?.payment_mode?customerState.selectedCustomerOrder.trip_payment?.payment_mode:"- - - - - -"}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripDetails;
