import React, { useEffect } from "react";
import searchicon from "../../assets/images/searchicon.svg";
import notification from "../../assets/images/notification.svg";
import closeImg from "../../assets/images/close-circle.svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
// import { searchTrip,contractRequestActions } from "../../redux/contractRequestsSlice";
// import { useEffect,useState } from "react";
// import { useSearchDebounce } from "../../common/useSearchDebounce";
import { contractManagementActions } from "../../redux/contractManagementSlice";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { deleteSingleNotification, getNotificationDashboard,dashboardActions,allClearNotification } from "../../redux/dashboardSlice";

const DashboardPageHeader = ({ pageTtitle, SubTitle }) => {
  const dispatch = useDispatch();
  const dashState = useSelector(state=>state.dashboard)
  const [dropdownClick, setdropdownClick] = useState(false);
  // const contractState = useSelector(state=>state.contractrequest)

useEffect(()=>{
  dispatch(dashboardActions.setLoader(true))
  dispatch(getNotificationDashboard())
},[dashState.notDeleteFlag])
useEffect(()=>{
  document.body.addEventListener("click",()=>{
    setdropdownClick(false)
  })
})
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

  return (
    <>
      <div className="grid grid-cols-2 md:grid-cols-1 gap-6 mt-3 mb-10 ">
        <div className="col-span-1">
          <div className="flex text-2xl font-bold leading-9 text-header-title tracking-wider">
            {pageTtitle}
          </div>
          {SubTitle && <SubTitle />}
        </div>
        <div className="col-span-1">
          <div className="flex flex-col items-end justify-start">
            {/* <div className="relative w-96">
              <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                <img src={searchicon} alt="" />
              </div>
              <input
                type="text"
                id="input-group-1"
                className="bg-bgcolor border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full pl-14 p-3.5 h-[39px]"
    
                onChange={(e)=> setTimeout(()=>dispatch(contractManagementActions.setSearch(e.target.value)),900) }
                placeholder="Search"
              />
            </div> */}
            <button
              type="button"
              className="border font-medium rounded-lg text-sm  text-center inline-flex items-center p-1 ml-6"
              onClick={(e)=>{
                e.stopPropagation()
                dispatch(dashboardActions.setLoader(true))
                dispatch(getNotificationDashboard())
                setdropdownClick(!dropdownClick)
              }}
            >
              <img
                src={notification}
                alt="Notification Icon"
                className="h-7 w-7"
              />
            </button>
            {/* /////////////////////////////////////////////////////////////////// */}
            <div className=" p-0 m-0" onClick={(e)=>   e.stopPropagation()}>
              <div className="notification_wrap">
                
                <div className={`dropdown ${dropdownClick ? "active" : ""} `}>
                <div className="flex justify-between items-center ">
                <p className="p-3 text-orange-500 font-bold text-xl">Notifications</p>
                <p className="font-light text-md cursor-pointer" onClick={()=>{
                    dispatch(dashboardActions.setLoader(true))
                  dispatch(allClearNotification())
                }}>Clear all</p>
                </div>
{dashState.loader?   <div style={{display:"flex",justifyContent:"center"}}>
          <Spin indicator={antIcon} />
        </div>:<div className="p-3 overflow-y-scroll scrollbar-color-dash">
  {/* {console.log(dashState.notificationData)} */}
                {dashState.notificationData.map(not=>(
                   <div className="notify_item pr-12" key={not.id}>
                 
                   <div className="notify_info">
                    <div className="flex  items-center justify-between">
                    <p className="w-[350px]">
                     {not.message}
                     </p>
                     <img onClick={()=>{
                       dispatch(dashboardActions.setLoader(true))
                      dispatch(deleteSingleNotification(not.id))}} className="w-[22px] cursor-pointer " src={closeImg} alt="" />
                    </div>
                     
                     
                   </div>
                 </div>
                ))}
                 
                 
               
               </div>}
                </div>
              </div>
            </div>
            {/* ////////////////////////////////////////////////////////////////////// */}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardPageHeader;
