import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { Link } from "react-router-dom";
import PageHeader from "../../layout/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import {
  acceptContractModification,
  rejectContractModification,
  cancellationReqAccept,
  contractModificationActions,
} from "../../redux/contractModificationSlice";
// import CustomerPageHeader from "./CustomerPageHeader";
import ModalLayout from "../../common/ModalLayout";
import { useNavigate } from "react-router-dom";
import ContractModHeaderWOS from "./ContractModHeaderWOS";
import { tConvert } from "../../common/timeConverter";
import { message } from "antd";

const ContractRescheduleRequest = () => {
  const contractModState = useSelector((state) => state.contractmodification);
  const authState = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [acceptModal, setAcceptModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [acceptCancellation, setAcceptCancellation] = useState(false);
  const [acceptDriverChange, setAcceptDriverChange] = useState(false);
  const [refundAmount, setRefundAmount] = useState("");
  const [contractModal, setContractModal] = useState(false);
  const [editAcess, setEditAcess] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();

  const success = (content) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };

  const error = (content) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };

  useEffect(() => {
    // console.log(authState.acessMenus)
    for (let i = 0; i < authState.acessMenus.length; i++) {
      if (authState.acessMenus[i].menu == "contract_modification") {
        //  console.log(authState.acessMenus[i].menu == "contract")
        //  console.log(Object.keys(authState.acessMenus[i]?.actions))
        for (
          let j = 0;
          j < Object.keys(authState.acessMenus[i]?.actions).length;
          j++
        ) {
          if (authState.acessMenus[i].actions.change) {
            // console.log(true)
            setEditAcess(true);
          }
        }
      }
    }
  }, []);

  const checkTripDateAlreadyStarted = (date)=>{
    const datesArray = date.split("/")
    const currentDate = new Date();
    const targetDate = new Date(datesArray[2], datesArray[1]- 1, datesArray[0]); 
    if(currentDate>targetDate){
      return true
    }else{
      return false
    }
  }

  useEffect(()=>{
    dispatch(contractModificationActions.clearAllFilters())
  },[])
  // console.log(contractModState.selectedModificationContract)
  const SubTitle = () => {
    return (
      <div className="flex items-center my-2">
        <Link
          to="/admin/contract-modification-requests"
          className="leading-5 text-base font-medium text-breadcrumb hover:text-black black-text"
        >
          Contract modification request
        </Link>
        <div className="inline-flex items-center justify-center w-2 h-2 mx-3 bg-breadcrumb border-breadcrumb rounded-full "></div>
        <span className="leading-5 text-base font-medium text-header-title">
          Reschedule request
        </span>
      </div>
    );
  };
  return (
    <div className="m-10">
      {contextHolder}
      <ContractModHeaderWOS
        pageTtitle="Reschedule request"
        SubTitle={SubTitle}
      />
      <div className="flex justify-between  bg-white rounded-3xl mb-8 gap-12 p-6 ">
        <div className="flex gap-8  w-[100%]">
          <div className="flex flex-col item-center w-[100%] gap-8">
            <div className="flex justify-between w-[100%] pb-4">
              <div>
                <p className="text-xl font-extrabold text-orange-500	">
                  {
                    contractModState.selectedModificationContract
                      .modification_status
                  }
                </p>
                <p className="text-md ">
                  Trip Id:
                  {contractModState.selectedModificationContract.trip_id}
                </p>
              </div>
              <div>
                {/* <p className="px-16 py-2 text-md bg-orange-100 rounded-lg font-bold text-orange-500">
                  Paid
                </p> */}
                <button
                  type="button"
                  className=" ml-10 w-[150px] h-[32px] lg:ml-5 my-1  text-orange-500 border-solid border-2 border-slate-500  rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
                  onClick={() => editAcess && setRejectModal(true)}
                >
                  Reject
                </button>
                <button
                  type="button"
                  className=" ml-10 w-[150px] h-[32px] lg:ml-5 my-1 bg-primary text-white border-solid border-2 border-slate-500  rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
                  onClick={() => {
                    // dispatch(acceptContractModification(contractModState.selectedModificationContract.id)).then(res=>{
                    //   if(res.meta.requestStatus){
                      if(checkTripDateAlreadyStarted(contractModState.selectedModificationContract.start_date) && (contractModState.selectedModificationContract.modification_status != "Cancel" && contractModState.selectedModificationContract.modification_status != "Driver Change")){
                        error("Trip start date has been already passed")

                  }else if(checkTripDateAlreadyStarted(contractModState.selectedModificationContract.end_date) && (contractModState.selectedModificationContract.modification_status == "Cancel" || contractModState.selectedModificationContract.modification_status == "Driver Change")){
                    error("Trip end date has been already passed")
                  }else{
                    if (editAcess) {
                      if (
                        contractModState.selectedModificationContract
                          .modification_status == "Renew" ||
                        contractModState.selectedModificationContract
                          .modification_status == "Reschedule"
                      ) {
                        dispatch(
                          acceptContractModification(
                            contractModState.selectedModificationContract.id
                          )
                        ).then((res) => {
                          setAcceptModal(true);
                          setTimeout(
                            () =>
                              navigate("/admin/contract-modification-requests"),
                            3000
                          );
                        });
                      } else if (
                        contractModState.selectedModificationContract
                          .modification_status == "Cancel"
                      ) {
                        setAcceptCancellation(true);
                      } else if (
                        contractModState.selectedModificationContract
                          .modification_status == "Driver Change"
                      ) {
                        setAcceptDriverChange(true);
                      }
                    }
                  }

                    //   }
                    // })
                  }}
                >
                  Accept
                </button>
              </div>
            </div>
            { contractModState.selectedModificationContract
                  .modification_status == "Reschedule"?"": <div className="flex justify-between w-[100%]  font-bold text-sm">
                  <p className="text-zinc-400	">
                    {" "}
                    {
                    contractModState.selectedModificationContract
                      .modification_status == "Driver Change"
                      ? "Journey start date and time"
                      : "Contract start date and time"}{" "}
                  </p>
                  <p>
                    {" "}
                    {contractModState.selectedModificationContract.start_date}{" "}
                    &nbsp; &nbsp;{" "}
                    {tConvert(
                      contractModState.selectedModificationContract.start_time
                    )}
                  </p>
                </div>}
           
            {contractModState.selectedModificationContract
              .modification_status == "Reschedule" && (
              <div className="flex justify-between w-[100%]  font-bold text-sm">
                <p className="text-zinc-400	"> Rescheduled date and time</p>
                <p>
                  {" "}
                  {contractModState.selectedModificationContract.start_date}{" "}
                  &nbsp; &nbsp;{" "}
                  {tConvert(
                    contractModState.selectedModificationContract.start_time
                  )}
                </p>
              </div>
            )}

            {contractModState.selectedModificationContract
              .modification_status == "Cancel" && (
              <div className="flex justify-between w-[100%]  font-bold text-sm">
                <p className="text-zinc-400	"> Contract end date and time</p>
                <p>
                  {" "}
                  {contractModState.selectedModificationContract.end_date}{" "}
                  &nbsp; &nbsp;{" "}
                  {tConvert(
                    contractModState.selectedModificationContract.end_time
                  )}
                </p>
              </div>
            )}

            {/* {contractModState.selectedModificationContract.stops && (
              <div className="flex justify-between w-[100%]  font-bold text-sm">
                <p className="text-zinc-400	"> Stops</p>
                <p>{contractModState.selectedModificationContract.stops}</p>
              </div>
            )} */}
            {/* {console.log(contractModState.selectedModificationContract
                      .modification_status)} */}
            <ModalLayout
              width={600}
              height={500}
              isModalOpen={contractModal}
              setIsModalOpen={setContractModal}
              className="flex justify-center items-center"
              style={{ marginBottom: "100px" }}
            >
              <div className="flex justify-between   bg-white rounded-3xl  gap-4 p-4 flex-col">
                <div className="flex justify-between w-[100%] pb-4 border-b  border-solid pb-4 border-slate-100">
                  <div className="">
                    <p className="text-2xl font-extrabold text-black	">
                      Contract proposition
                    </p>
                  </div>
                </div>
                <p className="text-red-500 font-bold text-xl">
                  Terms & Conditions
                </p>
                <p className="text-lg font-bold">Renew </p>
                - Requesting a new contract must be 3 days prior to the end of
                the running <br />
                contract <br />
                <p className="text-lg font-bold">Change Driver </p>
                - Please explain the reasons for requesting a change of driver.{" "}
                <br />
                - The driver is changed after reviewing the request by the
                Monthly Contracts <br />
                team, and then informing the customer of the new driver. <br />
                <p className="text-lg font-bold">Reschedule</p>
                - Please explain the reasons for rescheduling. <br />
                - Customer should provide us with the new location in case of
                changing the <br />
                location. <br />
                - Customer should provide us with the new timing in case of
                changing the time. <br />
                - Changing the details of the monthly contract could result in
                the change of the <br />
                driver. <br />
                <p className="text-lg font-bold">Cancel Contract / Trip</p>
                - Please explain the reasons for cancellation. <br />
                - Contract cancellation is confirmed after reviewing the
                mentioned reasons. <br />
                - In the event of cancellation, there are no refunds after 14
                days of the <br />
                contract’s validity <br />
              </div>
            </ModalLayout>

            <div className="flex justify-between w-[100%] font-bold text-sm">
              <p className="text-zinc-400	">Pickup location</p>
              <p>
             
                {
                  contractModState.selectedModificationContract.pickup_point?.adress
                }
              </p>
            </div>
           {contractModState.selectedModificationContract.trip_type == "Contract Trip" &&  <div className="flex justify-between w-[100%] font-bold text-sm">
              <p className="text-zinc-400	">Dropoff Location</p>
              <p>
                {
                  contractModState.selectedModificationContract.drop_of_point
                    ?.adress
                }
              </p>
            </div>}
            {(contractModState.selectedModificationContract
              .modification_status == "Reschedule" ||
              contractModState.selectedModificationContract
                .modification_status == "Driver Change") && (
              <div className="flex justify-between w-[100%] font-bold text-sm">
                <p className="text-zinc-400	">Booked for</p>
                <p>
                  {contractModState.selectedModificationContract.day_trip_hour
                    ? contractModState.selectedModificationContract
                        .day_trip_hour + " hour"
                    : contractModState.selectedModificationContract.no_of_days +
                      " days"}
                </p>
              </div>
            )}
            {/* {(contractModState.selectedModificationContract
              .modification_status == "Reschedule" ||
              contractModState.selectedModificationContract
                .modification_status == "Driver Change") && (
              <div className="flex justify-between w-[100%] font-bold text-sm">
                <p className="text-zinc-400	">Trip type</p>
                <p>{contractModState.selectedModificationContract.trip_type}</p>
              </div>
            )} */}

<div className="flex justify-between w-[100%] font-bold text-sm ">
              <p className="text-zinc-400	text-sm ">Trip type</p>
             {contractModState.selectedModificationContract.trip_type == "Contract Trip" &&  <p> {(contractModState.selectedModificationContract.trip_type =="Contract Trip"&& contractModState.selectedModificationContract.same_as_pickup_point)? "Two way trip" : "One way trip"}</p>}
              {contractModState.selectedModificationContract.trip_type == "Day Trip" && <p>{contractModState.selectedModificationContract.trip_type == "Day Trip" && contractModState.selectedModificationContract.trip_type }</p>}
            </div>



      {/* {contractModState.selectedModificationContract.trip_type == "Contract Trip" && <div className="flex justify-between w-[100%] font-bold text-sm ">
              <p className="text-zinc-400	text-sm ">Region</p>
                  <p>{contractModState.selectedModificationContract.region?.name}</p>
            </div>} */}
            {(contractModState.selectedModificationContract
              .modification_status == "Renew" ||
              contractModState.selectedModificationContract
                .modification_status == "Cancel") && (
              <div className="flex justify-between w-[100%]  font-bold text-sm">
                <p className="text-zinc-400	"> Driver type</p>
                <p>
                  {contractModState.selectedModificationContract.service_type}
                </p>
              </div>
            )}

            <div className="flex justify-between w-[100%] font-bold text-sm">
              <p className="text-zinc-400	">Driver and Vehicle details</p>
              <p
                onClick={() => {
                  contractModState.selectedModificationContract.trip_driver !=
                    null && navigate(`driverDetails`);
                }}
                className={`${
                  contractModState.selectedModificationContract.trip_driver ==
                  null
                    ? "text-red-300"
                    : "text-orange-500	cursor-pointer"
                } font-extrabold `}
              >
                {contractModState.selectedModificationContract.trip_driver ==
                null
                  ? "Driver not assigned "
                  : "Driver details"}
              </p>
            </div>
            <div
              onClick={() => {
                setContractModal(true);
              }}
              className="flex justify-between w-[100%] font-bold text-sm"
            >
              <p className="text-zinc-400	">Contract copy</p>
              <p className="text-orange-500	cursor-pointer font-extrabold">
                Contract proposition
              </p>
            </div>
          </div>
        </div>
      </div>
      <ModalLayout
        title="Add credit points"
        width={750}
        isModalOpen={acceptCancellation}
        setIsModalOpen={setAcceptCancellation}
        className="flex justify-center "
      >
        <hr />
        <div className="h-[300px] pt-20 px-14">
          <div className="flex flex-col ">
            <p className=" text-lg">Amount to be refunded</p>

            <div className="flex flex-col items-center">
              <input
                value={refundAmount}
                type="number"
                onChange={(e) => setRefundAmount(e.target.value)}
                id="first_name"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter amount"
                required
              />
              <button
                type="button"
                onClick={() => {
                  const data = {
                    credit_amount: refundAmount,
                    user_id:
                      contractModState.selectedModificationContract.user_id?.id,
                    trip_id: contractModState.selectedModificationContract.id,
                  };

                  if (refundAmount != "") {
                    dispatch(cancellationReqAccept(data));
                    setAcceptCancellation(false);
                  } else {
                    error("amount cannot be empty");
                  }
                }}
                className=" ml-10 mt-10 w-[300px] h-[30px] lg:ml-5 my-1 text-white bg-primary mt-5 rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </ModalLayout>
      <ModalLayout
        // title="Driver on boarding"
        width={500}
        isModalOpen={acceptModal}
        setIsModalOpen={setAcceptModal}
        className="flex justify-center items-center"
      >
        <div className=" h-[200px] flex  items-center text-center justify-center">
          <p className="text-xl font-extrabold text-orange-500 ">
            Ride information has been shared <br /> to the drivers
          </p>
        </div>
      </ModalLayout>
      <ModalLayout
        // title="Driver on boarding"
        width={500}
        isModalOpen={acceptDriverChange}
        setIsModalOpen={setAcceptDriverChange}
        className="flex justify-center items-center"
      >
        <div className=" h-[200px] flex flex-col  items-center text-center justify-around">
          <p className="font-bold text-xl pl-2">Send Message to the User</p>
          <button
            onClick={() => {
              dispatch(
                acceptContractModification(
                  contractModState.selectedModificationContract.id
                )
              ).then((res) => {
                setAcceptDriverChange(false);
                setAcceptModal(true);
                          setTimeout(
                            () =>
                              navigate("/admin/contract-modification-requests"),
                            3000
                          );
              });
            }}
            className=" ml-10 w-[250px] h-[30px] lg:ml-5 my-1 text-white bg-primary mt-5 rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
          >
            send
          </button>
        </div>
      </ModalLayout>
      <ModalLayout
        // title="Reason for rejection"
        width={750}
        isModalOpen={rejectModal}
        setIsModalOpen={setRejectModal}
        className="flex justify-center items-center"
      >
        <div className="h-[420px] ">
          <h1 className="text-2xl font-bold py-3">Reason for rejection</h1>
          <hr />
          <div className="py-7">
            <p className="pl-6 font-bold text-lg">Reason for rejection</p>
            {/* <input
                  type="text"
                  className="w-[95%] px-5 py-20 my-2 bg-bginputfield border-none font-medium text-sm  leading-6 rounded-lg outline-none"
                  placeholder="Enter reason"
                /> */}
            <div className="flex flex-col items-center">
              <textarea
                onChange={(e) => setRejectReason(e.target.value)}
                value={rejectReason}
                rows="10"
                cols="90"
                placeholder="Enter reason"
                className="mt-3 bg-bginputfield border-none font-medium text-md  leading-6 rounded-lg outline-none px-5 pt-4"
              ></textarea>
              <button
                type="button"
                onClick={() => {
                  const datas = {
                    id: contractModState.selectedModificationContract.id,
                    reason: rejectReason,
                  };
                  // console.log(datas)
                  if (rejectReason != "") {
                    dispatch(rejectContractModification(datas)).then(() => {
                      setRejectModal(false);
                      setTimeout(
                        () => navigate("/admin/contract-modification-requests"),
                        1000
                      );
                    });
                  } else {
                    error("this field cannot be empty");
                  }
                }}
                className=" ml-10 w-[250px] h-[30px] lg:ml-5 my-1 text-white bg-primary mt-5 rounded-[8px] leading-4 font-bold text-sm px-5 py-2 "
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </ModalLayout>

      {(contractModState.selectedModificationContract.modification_status ==
        "Cancel" ||
        contractModState.selectedModificationContract.modification_status ==
          "Driver Change") && (
        <div className="flex justify-between  bg-white rounded-3xl mb-8 gap-12 p-6 ">
          <div className="flex gap-8  w-[100%]">
            <div className="flex flex-col item-center w-[100%] ">
              <div className="flex justify-between w-[100%] pb-2">
                <p className="font-bold text-lg">
                  {contractModState.selectedModificationContract
                    .modification_status == "Cancel"
                    ? "Reason for cancellation"
                    : "Reason for driver change"}
                </p>
              </div>
              <div className="flex justify-between w-[100%] text-sm">
                <p className="text-black	">
                  {contractModState.selectedModificationContract
                    .modification_status == "Cancel"
                    ? contractModState.selectedModificationContract
                        .reason_for_cancelation
                    : contractModState.selectedModificationContract
                        .reason_for_change_driver}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-between  bg-white rounded-3xl mb-8 gap-12 p-6 ">
        <div className="flex gap-8  w-[100%]">
          <div className="flex flex-col item-center w-[100%] gap-8">
            <div className="flex justify-between w-[100%] pb-2">
              <p className="font-bold text-lg">Price and Payment details</p>
            </div>
            <div className="flex justify-between w-[100%] font-bold text-sm">
              <p className="text-zinc-400	">Cost</p>
              <p>{contractModState.selectedModificationContract.price} OMR</p>
            </div>
            <div className="flex justify-between w-[100%] font-bold text-sm">
              <p className="text-zinc-400	">Credit points added</p>
              <p>
                {contractModState.selectedModificationContract.credit_point} OMR
              </p>
            </div>
            <div className="flex justify-between w-[100%] font-bold text-sm">
              <p className="text-zinc-400	">Total Amount</p>
              <p>
                {contractModState.selectedModificationContract.total_amount} OMR
              </p>
            </div>
            <div className="flex justify-between w-[100%] font-bold text-sm">
              <p className="text-zinc-400	">Payment mode </p>
              <p>{contractModState.selectedModificationContract.trip_payment?contractModState.selectedModificationContract.trip_payment.payment_mode:"Not paid"}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractRescheduleRequest;
